import React from 'react';
import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import AnimatedBackground from './animatedBackground';

export default function CallToActionWithIllustration(props) {
  // Extracting color values outside of the component render
  const headingColor = useColorModeValue('orange.400', 'orange.300');
  const textColor = useColorModeValue('gray.500', 'gray.300');
  const buttonBg = useColorModeValue('orange.400', 'orange.300');
  const buttonHoverBg = useColorModeValue('orange.500', 'orange.400');

  return (
    <Container
      maxW={'5xl'}
      position="relative"
      zIndex="1"
      height="auto"
      paddingTop={{ base: '20', md: '28' }}
      paddingBottom={{ base: '20', md: '28' }}
    >
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}
        >
          Conquer Your Pilot Exams:{' '}
          <Text as={'span'} color={headingColor}>
            Train with Real Test Questions and Expert Tools
          </Text>
        </Heading>
        <Flex w={'400px'}>
          <img
            src="openart-cd51eb9f-f7a1-4556-9581-923e546c4637.png"
            alt="Illustration"
          />
        </Flex>

        <Text color={textColor} maxW={'3xl'}>
          Get the edge you need to pass your PPTL and ATPL exams with our online
          test platform, featuring real exam questions from past papers.
          Whether you're training for helicopter or airplane certification, our
          tailored tests and in-depth analysis prepare you for success. Start
          your journey with a free trial and experience the closest thing to the
          real exam—because your future in aviation deserves nothing less.
        </Text>
        <Stack spacing={6} direction={'row'}>
          <Button
            rounded={'full'}
            px={6}
            colorScheme={'orange'}
            bg={buttonBg}
            onClick={() => props?.onSignUp()}
            _hover={{ bg: buttonHoverBg }}
          >
            Start Free Trial
          </Button>
          <Button rounded={'full'} px={6}>
            Learn more
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
