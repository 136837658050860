import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack, HStack, Text, Badge, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import Helpers from '../../../../helpers'
// Individual Comment Component
const Comment = ({ user, comment, datetime, boxBgnew }) => (
  <Box bg={boxBgnew} p={4} borderRadius="md" shadow="md" w="full">
    <HStack style={{justifyContent:'space-between'}}>
    <Text fontWeight="bold">{user.firstName}</Text>
    <Badge colorScheme="teal">
    <Text fontWeight="bold">{Helpers.formatTimestamp(datetime)}</Text>
      </Badge>

    </HStack>

    <Text mt={2}>{comment}</Text>
  </Box>
);

// Main Comments Component
const Comments = (Props) => {
  const user = Props.user;
  const [text, setText] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBgnew = useColorModeValue("white", "whiteAlpha.100");

  // Add a new comment
  const handleAddComment = () => {
    if (user && text) {
        const datenow = Date.now()
      //setComments([...comments, { user, comment , date:datenow}]);
      setText("");    // Clear the comment text input
      Props.onNewComment(text);
    }
  };

  return (
    <Box maxW="lg" mt={8} >
            {/* Display Comments */}
            <VStack spacing={4} w="full">
          {Props.comments.length > 0 ? (
            Props.comments.map((comment, index) => (
              <Comment key={index} user={comment.user} comment={comment.comment} datetime={comment.datetime} boxBgnew={boxBgnew}  />
            ))
          ) : (
            <Text>No comments yet. Be the first to add one!</Text>
          )}
        </VStack>

      <VStack spacing={4} mt={3}>
        {/* Form to Add New Comment */}
        <FormControl id="comment-form" bg={boxBgnew} p={6} borderRadius="md" shadow="lg">
          
         

          <FormLabel colorScheme={'brand'} mt={4}>Comment</FormLabel>
          <Textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Write your comment"
            bg={boxBgnew}
          />

          <Button
            mt={4}
            colorScheme="teal"
            onClick={handleAddComment}
            isDisabled={!user || !text} // Disable button if inputs are empty
          >
            Add Comment
          </Button>
        </FormControl>

  
      </VStack>
    </Box>
  );
};

export default Comments;
