/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Image } from "@chakra-ui/react"
import {useHistory} from 'react-router-dom';

import { Center,InputRightElement, Box, Badge, Flex, Text, Button,Stack, List,Tag, ListItem, ListIcon, SimpleGrid, FormControl, FormLabel, Input, Select, InputGroup, InputLeftAddon, Spacer, VStack, HStack } from "@chakra-ui/react";
import UsersTable from "views/admin/users/components/UsersTable";
import {
  
  usersColumnsData,

} from "views/admin/users/variables/columnsData";
import 'react-quill/dist/quill.snow.css';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import tableDataDevelopment from "views/admin/users/variables/tableDataDevelopment.json";
import React, {useState, useEffect} from "react";
import Helpers from "helpers";
import { useParams } from 'react-router-dom';
import countryCodes from 'config/country_codes';
import Card from "components/card/Card";
import { queryByTestId } from "@testing-library/react";
import { Grid, GridItem } from "@chakra-ui/react"
import { IconButton } from "@chakra-ui/react"
import { DeleteIcon, QuestionOutlineIcon, AddIcon, InfoIcon, CheckIcon, WarningTwoIcon, ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
import ReactQuill from 'react-quill';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import helpers from "helpers";
import EditorToolbar, { modules,  formats } from "./components/CustomToolbar";
import default_language from "config/default_language";
import { defaultColumn } from "react-table";
import './style.css';

export default function EditQuestion(props) {

  const [questionId, setQuestionId] = useState("");
  const [newStudyName, setNewStudyName] = useState("");
  const [newSubjectName, setNewSubjectName] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [questionData, setQuestionData] = useState({});
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [isAddingStudyLoading, setIsAddingStudyLoading] = useState(false);
  const [isAddingSubjectLoading, setIsAddingSubjectLoading] = useState(false);
  const [nextPrev, setNextPrev] = useState({});

  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [dialCode, setDialCode] = useState("");
  const [backgroundColor, setBackGroundColor] = useState("");
  const [studies, setStudies] = useState([]);
  const [subjects, setSubjects] = useState({});
  const [subSubjects, setSubSubjects] = useState({});
  const [subSubSubjects, setSubSubSubjects] = useState({});

  const [addNewStudyOpened, setAddNewStudyOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

    const history = useHistory();

  let handle  = useParams();


  useEffect(()=>{


    console.log(handle);
  Helpers.getStudies().then((response)=>{
    console.log(response);
    setStudies(response);

  });


 



  }, []);


  


  useEffect(()=>{

    if(questionData?.study?.length)
    {

      questionData?.study.map((category =>{


  Helpers.getSubjects(category).then((response)=>{
    console.log(response);
    setSubjects({...subjects, [category]:response});

  });


      }))

    }




  }, [questionData?.study]);


  useEffect(()=>{

    if(questionData?.subject && questionData?.study)
    {

      questionData?.study.map((study =>{

        questionData?.subject.map((subject =>{
         

        
            Helpers.getSubjects(study, subject).then( (response)=>{
              console.log("we got subjects here");
              
              console.log(response);
  
              if(subSubjects[subject])
              {
                if(response)
                {
                  setSubSubSubjects({...subSubSubjects, [subject]:response});
  
                }
  
              }
              else{
                if(response)
                {
                setSubSubjects({...subSubjects, [subject]:response});
                }
  
              }
          
            });
          
            

          

         


          
        
        
              }))
       
      
      
            }))



    }




  }, [questionData?.subject]);



  function getStudyTitlebyId(id) {
    // Loop through the array
    for (const item of studies) {
      // Check if the object's _id matches the provided id
      if (item._id === id) {
        // If there's a match, return the title
        return item.title;
      }
    }
    // If no match is found, return undefined
    return undefined;
  }

  const onUpdateQuestionClick = () =>
  {

    setIsSavingLoading(true);
    Helpers.updateQuestion(questionData).then((response)=>{
      if(response?.explanation?.images)
      {
        response.explanation_images = response.explanation.images;
      }
      setQuestionData(response);
      setIsSavingLoading(false);
      setSuccess(true);
      setTimeout(function() {
        setSuccess(false);

    }, 3000);
     

    });

  }
  const onAddNewStudy = () =>
  {

    setIsAddingStudyLoading(true);
    Helpers.addStudy({title:newStudyName, desription:""}).then((response)=>{
      //setQuestionData(response);
      setIsAddingStudyLoading(false);

    });

  }




  useEffect(()=>{

    if(questionData)
    { 
      console.log("question data updated");
      console.log(questionData)
   
  
    }


  //setDialCode(getDialCode(questionData?.address?.country));



  }, [questionData]);



  useEffect(()=>{

if(handle.questionId)
{
  setQuestionId(handle.questionId);
  Helpers.getQuestion(handle.questionId).then(async (response)=>{
    response.explanation_images = response?.explanation?.images;

        setQuestionData(response);
       
      });


      Helpers.getNextPrevQuestions(handle.questionId).then(async (response)=>{
        console.log('response for next prev is', response);
    
            setNextPrev(response);
           
          });
}



  }, [handle]);

  useEffect(()=>{

    if(questionData.status)
    {
      setError(false);
      if(!questionData.options[0].text)
      {
        setError(true);
      }
      if(!questionData.options[1].text)
      {
        setError(true);
      }
      if(!questionData.options[2].text)
      {
        setError(true);
      }
      if(!questionData.options[3].text)
      {
        setError(true);
      }
      if(!questionData?.explanation?.text)
      {
        setError(true);
      }
      if(!questionData.text)
      {
        setError(true);
      }
      if(!questionData.study.length)
      {
        setError(true);
      }
      if(!questionData.subject.length)
      {
        setError(true);
      }
      if(!questionData.correctAnswer)
      {
        setError(true);
      }

    }
    
    
    
      }, [questionData]);




  const handleAddOption = () => {
    const updatedOptions = [...questionData.options];
    const nextId = String.fromCharCode(
      'a'.charCodeAt(0) + questionData.options.length
    );
    updatedOptions.push({ id: nextId, text: "" });

    setQuestionData((prevState) => ({ ...prevState, options: updatedOptions }));

  };

  const handleRemoveOption = (index) => {
    setQuestionData((prevState) => {
      const updatedOptions = [...prevState.options];
      updatedOptions.splice(index, 1);
  
      // Regenerate IDs for remaining options
      for (let i = index; i < updatedOptions.length; i++) {
        updatedOptions[i].id = String.fromCharCode('a'.charCodeAt(0) + i);
      }
  
      return { ...prevState, options: updatedOptions };
    });
  };

  const handleRemoveExplanationImage = (index) => {
    setQuestionData((prevState) => {
      const updatedImages = [...prevState.explanation_images];
      updatedImages.splice(index, 1);
  
   
  
      return { ...prevState, explanation_images: updatedImages };
    });
  };

  const handleAiTweak = async (imageurl) => {

    const airesponse = await Helpers.aiTweak(imageurl);
    if(airesponse.imagepath)
    {

      setQuestionData(prevState => ({
        ...prevState,
        explanation_images: [...prevState?.explanation_images, airesponse.imagepath]
        }))
        
    }



  };

  const handleAiTweakMain = async (imageurl) => {

    const airesponse = await Helpers.aiTweak(imageurl);
    if(airesponse.imagepath)
    {

      setQuestionData(prevState => ({
        ...prevState,
        images: [...prevState?.images, airesponse.imagepath]
        }))
        
    }



  };

  const handleContrastTweak = async (imagepath) => {

    const airesponse = await Helpers.contrastTweak(imagepath);
    if(airesponse.imagepath)
    {

      setQuestionData(prevState => ({
        ...prevState,
        explanation_images: [...prevState?.explanation_images, airesponse.imagepath]
        }))
        
    }



  };
  const handleContrastTweakMain = async (imagepath) => {

    const airesponse = await Helpers.contrastTweak(imagepath);
    if(airesponse.imagepath)
    {

      setQuestionData(prevState => ({
        ...prevState,
        images: [...prevState?.images, airesponse.imagepath]
        }))
        
    }



  };

  const handleColorsTweak = async (imagepath) => {

    const airesponse = await Helpers.colorsTweak(imagepath);
    if(airesponse.imagepath)
    {

      setQuestionData(prevState => ({
        ...prevState,
        explanation_images: [...prevState?.explanation_images, airesponse.imagepath]
        }))
        
    }



  };

  const handleColorsTweakMain = async (imagepath) => {

    const airesponse = await Helpers.colorsTweak(imagepath);
    if(airesponse.imagepath)
    {

      setQuestionData(prevState => ({
        ...prevState,
        images: [...prevState?.images, airesponse.imagepath]
        }))
        
    }



  };

  const handleRemoveImage = (index) => {
    setQuestionData((prevState) => {
      const updatedImages = [...prevState.images];
      updatedImages.splice(index, 1);
  
   
  
      return { ...prevState, images: [...updatedImages] };
    });
    
  };


  const onClickApproveRealExam = (id) =>{


  }
  

  const handleFileChangeExplanationImages = async (event) => {
    console.log(event.target.files[0]);
   // setSelectedFile(event.target.files[0]);
    const uploadResponse = await Helpers.uploadFile(event.target.files[0]);
    console.log(uploadResponse);
    if(uploadResponse)
    {
      if(uploadResponse.success)
      {
        
        setQuestionData(prevState => ({
          ...prevState,
          explanation_images: [...prevState?.explanation_images, uploadResponse.filename]
          }))
      }
  
    }
};


const handleFileChangeImages = async (event) => {
  console.log(event.target.files[0]);
 // setSelectedFile(event.target.files[0]);
  const uploadResponse = await Helpers.uploadFile(event.target.files[0]);
  console.log(uploadResponse);
  if(uploadResponse)
  {
    if(uploadResponse.success)
    {
      setQuestionData(prevState => ({
        ...prevState,
        images: [...prevState.images, uploadResponse.filename]
        }))
    }

  }
};

function getTitleById(array, id) {
  // Search for the object with the matching _id
  const item = array.find(item => item._id === id);

  // If the object is found, return its title; otherwise, return null
  return item ? item.title : null;
}

  // Chakra Color Mode
  return (
    <Flex>
    <Box w={{ sm: "100%", md: "100%", lg:"100%" }} display={{md:'flex', sm:'block'}} gap={2} alignItems='baseline'>
    <Box w={{ sm: "100%", md: "80%", lg:"80%" }} >
          <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
          mb='20px'
          columns={{   sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>

<Alert status='error' style={(error && questionData?.status == "active")? {} : {'display':'none'}}>
  <AlertIcon />
  <AlertTitle>Fields missing</AlertTitle>
  <AlertDescription>Please check all the fields </AlertDescription>
</Alert>



          <Card >

          <Flex>
          <Button disabled={nextPrev?.prevQuestionId == null}  leftIcon={<ArrowBackIcon />}  onClick={()=> history.push('/admin/question/'+nextPrev?.prevQuestionId)} colorScheme='brand' >
    Previous Question
  </Button>
  <Spacer />
  <Button disabled={nextPrev?.nextQuestionId == null} rightIcon={<ArrowForwardIcon />} onClick={()=> history.push('/admin/question/'+nextPrev?.nextQuestionId)} colorScheme='brand' >
    Next Question
  </Button>
</Flex>
       
          {questionData && <FormControl  isRequired >
          <FormLabel>Question ID</FormLabel>
          <Text>{questionData?.internal_id}</Text>

      
          



          </FormControl>}


         {questionData && <FormControl  isRequired >
          <FormLabel>{props.default_language.question}</FormLabel>

          <ReactQuill 
          theme={'snow'} 
          value={questionData?.text} 
          onChange={(event)=>  setQuestionData((prevState) => ({ ...prevState, text: event }))} 
          formats={formats}
          />

          <Input style={{display:'none'}} placeholder={props.default_language.question} borderRadius="16px" value={questionData?.text} onChange={(event)=>setQuestionData(prevState => ({
          ...prevState,
          text: event.target.value,
          }))} />



          </FormControl>}

          <FormControl>
          <FormLabel>Question Images</FormLabel>
            <Stack direction="row" columns={5} spacing='10px'>
            {questionData?.images &&  questionData?.images?.map((image, index)=>{
                return ( <Box>
                      <VStack>
                  <Button
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleAiTweakMain(Helpers.getImageURL(image))}
          >AI Tweak</Button>

<Button
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleContrastTweakMain(image)}
          >Contrast Tweak</Button>

<Button
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleColorsTweakMain(image)}
          >Color Tweak</Button>
          
                  </VStack>
                  <Image
                  boxSize="100px"
                  objectFit="cover"
                  onClick={()=>window.open(Helpers.getImageURL(image), '_blank')}
                  src={Helpers.getImageURL(image)}
                  alt="Horizon UI"
                  />
                  <IconButton
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleRemoveImage(index)}
          icon={<DeleteIcon />}
          />
                  </Box>);

              })}
              <Box>
                <Input type="file" id="fileInput" onChange={handleFileChangeImages} display="none"  />
                <IconButton
  colorScheme='brand'
  as="label"
  aria-label='Add Photo'
  size='lg'
  htmlFor="fileInput"
  icon={<AddIcon />}
/>
                  
                        

    

              </Box>
           
          
              
           
            
            </Stack  >
            </FormControl>

          <Card>

          {questionData.options &&  questionData.options.map((answer, index) =>  
          <Flex align={"center"} grow={2}>
          <Box w={'10%'} align={'center'} style={ answer.id == questionData.correctAnswer ? {backgroundColor:'green', color:'white', padding:'20px', borderRadius:10, margin:10} :  {backgroundColor:'#d3d3d3', padding:'20px', borderRadius:10, margin:10}} onClick={(event)=>setQuestionData(prevState => ({
          ...prevState,
          correctAnswer: answer.id,
          }))}>
          <Text>{answer.id}</Text>
          </Box>
          <Box w={'80%'} align={'center'} >

          <FormControl id="first-name" isRequired style={{margin:20}} >

          
            
          <Input  placeholder={props.default_language.answer} borderRadius="16px" value={answer?.text.replace(/<[^>]*>?/gm, '')} onChange={(event) => {
          setQuestionData((prevState) => ({
          ...prevState,
          options: prevState.options.map((option) =>
          option.id === answer.id
          ? { ...option, text: event.target.value }
          : option
          ),
          }));
          }}/>



          
          </FormControl>

          </Box>
          <Box w={'10%'} align={'center'}>
          <IconButton
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleRemoveOption(index)}
          icon={<DeleteIcon />}
          style={{display:'None'}}
          />

          </Box>



          </Flex>

          )
          }

          </Card>
          <Button onClick={handleAddOption}  variant="brand" size="md"           style={{display:'None'}}
>{props.default_language.add_answer}</Button>

          <FormControl>
          <FormLabel>{props.default_language.explanation}</FormLabel>
          <div className="text-editor">

          <EditorToolbar />
          <ReactQuill 
          
          theme={'snow'} 
          value={questionData?.explanation?.text} 
          onChange={(event)=>  setQuestionData((prevState) => ({ ...prevState, explanation: { ...questionData?.explanation, text: event } }))} 
          modules={modules}
          formats={formats}
          />
          </div>
          </FormControl>
         
         
          <FormControl>
          <FormLabel>{props.default_language.explanation_images}</FormLabel>
            <Stack direction="row" columns={5} spacing='10px'>
            {questionData?.explanation_images &&  questionData?.explanation_images?.map( (image, index)=>{
                return ( <Box style={{justifyContent:'center', alignItems:'center'}}>
                  <VStack>
                  <Button
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleAiTweak(Helpers.getImageURL(image))}
          >AI Tweak</Button>

<Button
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleContrastTweak(image)}
          >Contrast Tweak</Button>

<Button
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleColorsTweak(image)}
          >Color Tweak</Button>
          
                  </VStack>
           
          
                  <Image
                  boxSize="100px"
                  objectFit="cover"
                  onClick={()=>window.open( Helpers.getImageURL(image), '_blank')}
                  onError={(e) => {
                    e.target.src = Helpers.getImageURL(image); // Retry with cache-busting
                  }}
                  src={Helpers.getImageURL(image)}  // Cache busting parameter
                  alt="Horizon UI"
                  />
                  <IconButton
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleRemoveExplanationImage(index)}
          icon={<DeleteIcon />}
          />
                  </Box>);

              })}
              <Box>
                <Input type="file" id="fileInputexplanation" onChange={handleFileChangeExplanationImages} display="none" />
                <IconButton
  colorScheme='brand'
  as="label"
  aria-label='Add Photo'
  size='lg'
  htmlFor="fileInputexplanation"
  icon={<AddIcon />}
/>
                  
                        

    

              </Box>
           
          
              
           
            
            </Stack  >
            </FormControl>

            


          </Card>

          


          </SimpleGrid>
          </Box>

          <Box>

            <Card>
              <FormLabel>
                {default_language.real_exams}
              </FormLabel>
              <FormControl>
              <List spacing={2}>
                {questionData?.realExams && questionData?.realExams.map((realExam)=>{
                  return (
                      <ListItem>

<Grid
  templateRows='repeat(2, 1fr)'
  templateColumns='repeat(5, 1fr)'
  gap={2}
>
  <GridItem rowSpan={1} colSpan={3}  > 
  <ListIcon as={realExam.approved ? CheckIcon: WarningTwoIcon} color='green.500' />

  {realExam?.countryName}
  <SimpleGrid>
  {realExam?.markedBy ? default_language.markedBy+" "+realExam?.markedBy: ""}



  </SimpleGrid>

</GridItem>

  <GridItem colSpan={1}>
    <Badge colorScheme='teal'>
  {realExam?.count}
    </Badge>
    </GridItem>

  <GridItem colSpan={1}  >
    <Text fontSize='sm'> 
  <Tag size={"md"} key={"md"} variant='solid' colorScheme='teal'>
    {realExam?.approved ? default_language.approved: default_language.pending}
    </Tag>
    {realExam?.approved && " by"} 
     {realExam?.approved && realExam?.approvedBy  ? realExam?.approvedBy :  realExam?.approved ? " Admin": ""}
     {!realExam?.approved ? <Button onClick={()=>onClickApproveRealExam(realExam._id)} colorScheme={"teal"}>{default_language.approve}</Button> : ""}
     </Text>
    </GridItem>

 
</Grid>




                
                    

                  </ListItem>
               
                    
                  )


                }) }
                </List>

  

              </FormControl>
              </Card>
          </Box>

    </Box>

    <Box w={{ sm: "100%", md: "20%" }} pt={{ base: "130px", md: "80px", xl: "80px" }}>


    <Card style={{marginBottom:10}}>
      <FormControl >
  <FormLabel>{props.default_language.status}</FormLabel>
  
    <Select value={questionData?.status} onChange={(event)=>  setQuestionData((prevState) => ({ ...prevState, status: event.target.value }))}>
      <option value="draft">{props.default_language.draft}</option>
      <option value ="pending">{props.default_language.pending}</option>
      <option value="active">{props.default_language.active}</option>


    </Select>



  


</FormControl> 
</Card> 




          <Card style={{marginBottom:10}}>
      <FormControl >
  <FormLabel>{props.default_language.study}</FormLabel>
  
    {studies.map((study)=>{
      return (  
        <Flex>

      
        <Checkbox
        style={{marginTop:10}}
        isChecked={questionData?.study?.includes(study._id) ?? false}
        value={study._id}
onChange={(event) => {
  var updatedStudies = [...questionData?.study || []]; // Handle potential undefined study
  if (event.target.checked == true) {

      if(!updatedStudies.includes(study._id))
      {
        updatedStudies.push(study._id); // Add study ID if checked
        setQuestionData((prevState) => ({ ...prevState, study: updatedStudies }));

      }
  } else {
    const filteredStudies = updatedStudies.filter(_id => _id !== study._id); // Remove study ID if unchecked
    setQuestionData((prevState) => ({ ...prevState, study: filteredStudies }));
  }

  // Optional: Log updated studies for debugging
}}      >
        {study.title}
        </Checkbox>
        </Flex>

      )

    })}
<Flex style={{marginTop:20, display:'None'}}>

<Button variant="brand" isLoading={isSavingLoading} onClick={()=> { setAddNewStudyOpened(true);  } }>{props.default_language.add_study}</Button>

</Flex>



<Flex style={addNewStudyOpened ? {}: {display:'None'}}>
<Input placeholder={props.default_language.study} borderRadius="16px" value={newStudyName} onChange={(event) => {
          setNewStudyName(event.target.value);
          }}/>
<Button variant="brand" isLoading={isAddingStudyLoading} onClick={()=>onAddNewStudy()}>{props.default_language.add}</Button>

</Flex>

  


</FormControl> 
</Card> 


<Card style={questionData?.study?.length ? {marginBottom:10} : {display:'None', marginBottom:10}}>

  
    
    
    {
    
    Object.keys(subjects).map((k, i) =>{
      console.log(subjects);

      const subject_array = subjects[k];
      console.log(subject_array);

      return (
        <FormControl style={questionData?.study?.includes(k) ?{ } :{display:'None'}} >
  <FormLabel>{props.default_language.subject} for {getStudyTitlebyId(k)}</FormLabel>

       {questionData?.study?.includes(k) && subject_array.map((subject) => {

        return (  
          <Box style={{marginBottom:10}}>

       
          <Checkbox
          isChecked={questionData?.subject?.includes(subject._id) ?? false}
          value={subject._id}
  onChange={(event) => {
    var updatedSubjects = [...questionData?.subject || []]; // Handle potential undefined study
  console.log(event);
    if (event.target.checked == true) {
  
        if(!updatedSubjects.includes(subject._id))
        {
          updatedSubjects.push(subject._id); // Add study ID if checked
          setQuestionData((prevState) => ({ ...prevState, subject: updatedSubjects }));
  
        }
    } else {
      console.log("we are here");
      const filteredSubjects = updatedSubjects.filter(_id => _id !== subject._id); // Remove study ID if unchecked
      setQuestionData((prevState) => ({ ...prevState, subject: filteredSubjects }));
    }
  
    // Optional: Log updated studies for debugging
    console.log("Updated subjects:", updatedSubjects);
  }}      >
          {subject.title}






        






          </Checkbox>
          <Box pl={4} spacing={1} style={{marginTop:10}}>

          {questionData?.subject?.includes(subject._id) && subSubjects[subject._id] &&  subSubjects[subject._id].map((subSubject) => (
            <Box style={{marginTop:10}}>
              <Checkbox 
              key={subSubject._id} 
              isChecked={questionData?.subject?.includes(subSubject._id) ?? false}
              value={subSubject._id}
              onChange={(event) => {
                var updatedSubjects = [...questionData?.subject || []]; // Handle potential undefined study
              console.log(event);
                if (event.target.checked == true) {
              
                    if(!updatedSubjects.includes(subSubject._id))
                    {
                      updatedSubjects.push(subSubject._id); // Add study ID if checked
                      setQuestionData((prevState) => ({ ...prevState, subject: updatedSubjects }));
              
                    }
                } else {
                  console.log("we are here");
                  const filteredSubjects = updatedSubjects.filter(_id => _id !== subSubject._id); // Remove study ID if unchecked
                  setQuestionData((prevState) => ({ ...prevState, subject: filteredSubjects }));
                }
              
                // Optional: Log updated studies for debugging
                console.log("Updated subjects:", updatedSubjects);
              }} 
              >
                
              {subSubject.title}
              
              </Checkbox>
             <Box pl={4} spacing={1}>

              {questionData?.subject?.includes(subSubject._id) && subSubSubjects[subSubject._id] &&  subSubjects[subSubject._id].map((subSubSubject) => (
              <Checkbox 
              key={subSubSubject._id} 
              isChecked={questionData?.subject?.includes(subSubSubject._id) ?? false}
              value={subSubSubject._id}
              onChange={(event) => {
                var updatedSubjects = [...questionData?.subject || []]; // Handle potential undefined study
              console.log(event);
                if (event.target.checked == true) {
              
                    if(!updatedSubjects.includes(subSubSubject._id))
                    {
                      updatedSubjects.push(subSubSubject._id); // Add study ID if checked
                      setQuestionData((prevState) => ({ ...prevState, subject: updatedSubjects }));
              
                    }
                } else {
                  console.log("we are here");
                  const filteredSubjects = updatedSubjects.filter(_id => _id !== subSubSubject._id); // Remove study ID if unchecked
                  setQuestionData((prevState) => ({ ...prevState, subject: filteredSubjects }));
                }
              
                // Optional: Log updated studies for debugging
                console.log("Updated subjects:", updatedSubjects);
              }} 
              >
                {subSubSubject.title}
                </Checkbox>
              ))}
</Box>
            </Box>
            
          ))}
          </Box>
          </Box>
              

  
  
        )



      })}
      </FormControl> 
    )
    
     

    }
    
    
    ) }

  



</Card>





<Card>
<Alert status='success' style={(success)? {} : {'display':'none'}}>
  <AlertIcon />
  <AlertTitle>Question Updated</AlertTitle>
</Alert>
  <Button variant="brand" isDisabled={ (error ) ? true :false } isLoading={isSavingLoading} onClick={()=>onUpdateQuestionClick()}>{props.default_language.update_question}</Button>
</Card>


<Card style={{marginTop:'10px'}}>
<FormLabel>
                <strong>Latests Updates</strong>

                {questionData?.modifiedBy && questionData?.modifiedBy.map(modification=>{
                  return (
                  <Text><strong>{Helpers.formatTimestamp(modification.date)}</strong> - {modification.user.firstName} {modification.user.lastName}</Text>
                );
                })}
              </FormLabel>
</Card>





    </Box>
    </Box>
      
      </Flex>
  );
}
