import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack, HStack, Text, Badge, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import Helpers from '../../../../helpers'
// Individual Comment Component
const RealExam = ({ realExam, handleAddRealExam, boxBgnew, textColor }) => (

 


  <Box    bg={boxBgnew} 
  textColor={textColor}  p={4} borderRadius="md" shadow="md" w="full" _hover={{backgroundColor:'teal', color:'white', cursor:'pointer'}}>
    <HStack style={{justifyContent:'space-between'}}>
    <Text fontWeight="bold">{realExam.countryName}</Text>
    

    <Badge colorScheme="teal">
    <Text fontWeight="bold">{realExam.count}</Text>
      </Badge>

       
    </HStack>
  </Box>)


// Main Comments Component
const RealExams = (Props) => {
  const user = Props.user;
  const [text, setText] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBgnew = useColorModeValue("white", "whiteAlpha.100");
  const handleAddRealExam = async(countryName) =>{
      
      
  }
  // Add a new comment
  const handleAddComment = () => {
    if (user && text) {
        const datenow = Date.now()
      //setComments([...comments, { user, comment , date:datenow}]);
      setText("");    // Clear the comment text input
      Props.onNewComment(text);
    }
  };

  return (
    <Box maxW="lg" mt={8}>
       
            {/* Display Comments */}
            <VStack spacing={4} w="full">
          {Props.realExams.length > 0 ? (
            Props.realExams.map((realExam, index) => (
              <RealExam boxBgnew={boxBgnew} textColor={textColor} key={index} realExam={realExam} handleAddRealExam={handleAddRealExam} />
            ))
          ) : (
            <Text>No comments yet. Be the first to add one!</Text>
          )}
        </VStack>

            


    </Box>
  );
};

export default RealExams;
