// Chakra imports
import { Portal, Box, useDisclosure, Text, Button, Link } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import NavbarHome from 'components/navbar/NavbarHome.js';
import SignUpModal from 'components/home/signupModal'; // Update path as needed

import Hero from 'components/home/hero.js';
import Pricing from 'components/home/pricing.js';
import Features from 'components/home/features.js';
import Contact from 'components/home/contactNew.js';
import Faq from 'components/home/faq.js';

import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { createStandaloneToast } from '@chakra-ui/react'
import Helpers from '../../helpers'
// Custom Chakra theme
export default function Home(props) {
	const toast = createStandaloneToast()

	const { ...rest } = props;
	// states and functions
	const [ fixed ] = useState(false);
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	// functions for changing the states from components
	const [profileData, setProfileData] = useState(null);
	const [activeRoutes, setActiveRoutes] = useState([]);

	const [isSignUpOpen, setSignUpOpen ] = useState(false);
	const  [plans, setPlans] = useState([])
	const [authToken, setAuthToken] = useState('');

	useEffect(()=>{

		getPlans();
		getAuthToken();


	},[])

	const getAuthToken = async()=> {
		const token  = await Helpers.getAuthToken();
		if(token)
		{
			setAuthToken(token);
		}
	}
	

	const getPlans = async()=>{

		const plansResponse = await Helpers.getPlansPublic();

		if(plansResponse)
		{
			setPlans(plansResponse);
		}
	}

	const getRoute = () => {

		
	
			return window.location.pathname !== '/admin/full-screen-maps';

	
	
	
	 
	
		
	  };

	  const sendMessage = async(data) => {



		toast({
			title: 'Message Sent.',
			description: "We have succesfully received your message!.",
			status: 'success',
			duration: 9000,
			isClosable: true,
		  })




	  }


	
	
	
	
	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();
	document.documentElement.dir = 'ltr';
	return (
		<Box>
			<Box>
				<NavbarHome token={authToken}/>
				<Hero  onSignUp={()=>setSignUpOpen(true)}/>
				<Features/>
				<Faq/>
				<Pricing plans={plans} />
				<Contact sendMessage={(data)=>sendMessage(data)}/>
				<SignUpModal isOpen={isSignUpOpen} onClose={()=>setSignUpOpen(false)} />
			     <Footer />

			</Box>


		</Box>
	);
}
