import React from "react";
import { MdOutlineKebabDining } from "react-icons/md";
import { Quill } from "react-quill";
var Embed = Quill.import('blots/block/embed');

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

const CustomHr= () => (
    <hr style={{borderColor:'black'}}/>
  );
  
  


// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);
class Hr extends Embed {
    static create(value) {
        let node = super.create(value);
        // give it some margin
        node.setAttribute('style', "height:1px; margin-top:10px; margin-bottom:10px;");
        return node;
    }
}

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);
Hr.blotName = 'hr'; //now you can use .ql-hr classname in your toolbar
Hr.className = 'my-hr';
Hr.tagName = 'hr';

Quill.register({
    'formats/hr': Hr
});
// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

const hrHandler = function(){
    // get the position of the cursor
    var range = this.quill.getSelection();
    if (range) {
        // insert the <hr> where the cursor is
        this.quill.insertEmbed(range.index,"hr","null")
    }
}


var symbolHandler = function(value, quill) {
  console.log("props here");
    console.log(value);
  
   var range = quill.getSelection();
    if (range) {
      // insert the <hr> where the cursor is
      //this.quill.insertEmbed(range.index,"hr","null")
  
      quill.insertText(range.index, value);
      quill.setSelection(range.index + value.length);
  }
  


  
}


const symbols_to_be_added = "√¼½¾Øß÷…⇒⇔≈αβγρφθλωµ";
const symbols_to_be_added_array = symbols_to_be_added.split('');
var moduleSymbolHandlers = {};
var symbolFormats = [];
var buttonsSymbolsArray = [];
 
symbols_to_be_added_array.map((symbol, index)=> {
 moduleSymbolHandlers['custom_symbol_'+index] = function() {
  symbolHandler(symbol, this.quill);
}

 
  symbolFormats.push('custom_symbol_'+index);
  buttonsSymbolsArray.push( <button className={'ql-custom_symbol_'+index}>
  <span>{symbol}</span>
</button>);


})


// Modules object for setting up the Quill editor
  export const modules = {
  toolbar: {
    class: "default",
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      'hr': hrHandler,
      ...moduleSymbolHandlers
    
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};




console.log("module handlers are");

console.log(modules.handlers);
// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
  "hr"
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size" defaultValue="medium">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
   
      {buttonsSymbolsArray.map((element)=>{
        return (element)
      })}
      
      <button className="ql-hr">
        <CustomHr />
      </button>
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    

    
    </span>
  </div>
);

export default QuillToolbar;
