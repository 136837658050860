import default_language from "config/default_language";
export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const questionsColumnsData = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "User",
    accessor: "user",
  },
  {
    Header: default_language.question,
    accessor: "text",
  },
  {
    Header:default_language.date_added,
    accessor: "createdAt",
  },
  {
    Header: default_language.date_modified,
    accessor: "modifiedAt",
  },
  {
    Header: default_language.actions,
    accessor: "actions",
  },
];

export const plansColumnsData = [
  {
    Header: "Name",
    accessor: "name",
  },
 
  {
    Header: "Interval",
    accessor: "interval",
  },
  {
    Header:"Price",
    accessor: "price",
  }
];

