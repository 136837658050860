/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid, VStack, useColorModeValue } from "@chakra-ui/react";


import default_language from "config/default_language";

// Assets

import React from "react";

import countryCodes from 'config/country_codes';
import {  Badge, Flex, Text, Button,Stack, SimpleGrid, FormControl, FormLabel, Input, Select, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Helpers from '../../../helpers'

export default function Overview(props)
{
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBgnew = useColorModeValue("white", "whiteAlpha.100");

  const textColor = useColorModeValue("black", "white");

  console.log("allofourpops", props );
  const [userData, setUserData] = useState(props.profileData);
  const [dialCode, setDialCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const handleUpdateClick = async () => {

      setIsLoading(true);
     const newUserData =  await Helpers.updateProfile(userData);

      setUserData(newUserData);
      
      setIsLoading(false);

      try{
        props.reFetchProfileData();
      }
      catch{
        
      }


  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
     
  <Box bg={boxBgnew} p={10} borderRadius={10}>


<VStack>


<FormControl id="first-name" isRequired colorScheme="brand">
  <FormLabel>{default_language.firstName}</FormLabel>
  <Input color={textColor} placeholder="First name" borderRadius="16px" value={userData?.firstName} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    firstName: event.target.value,
}))} />
</FormControl>
<FormControl id="last-name" isRequired>
  <FormLabel>{default_language.lastName}</FormLabel>
  <Input color={textColor} placeholder="First name" borderRadius="16px" value={userData?.lastName} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    lastName: event.target.value,
}))} />
</FormControl>
<FormControl id="email" isRequired>
  <FormLabel>{default_language.email}</FormLabel>
  <Input color={textColor} placeholder="Email" borderRadius="16px" value={userData?.email} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    email: event.target.value,
}))} />
</FormControl>
<FormControl id="country" >
  <FormLabel>{default_language.country}</FormLabel>
  <Select color={textColor} placeholder={default_language.select_country} value={userData?.address?.country} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, country:event.target.value},
}))}>
    {countryCodes.map((country)=>{
      return (  
      <option value={country.code}>{country.name}</option>
      )

    })}
  
</Select>

</FormControl> 
</VStack>
<VStack>


<FormControl id="phone" >
<FormLabel>{default_language.phone}</FormLabel>

<InputGroup>
    <InputLeftAddon children={dialCode} borderRadius="16px" />
    <Input color={textColor} type="tel" placeholder={default_language.phone} borderRadius="16px" value={userData?.phoneNumber} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    phoneNumber: event.target.value,
}))} />
  </InputGroup>
  </FormControl>

<FormControl id="street" >
  <FormLabel>{default_language.street_address}</FormLabel>
  <Input color={textColor} placeholder={default_language.street} borderRadius="16px" value={userData?.address?.street} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, street:event.target.value},
}))} />
</FormControl>
<FormControl id="city" >
  <FormLabel>{default_language.city}</FormLabel>
  <Input  color={textColor} placeholder={default_language.city} borderRadius="16px" value={userData?.address?.city} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, city:event.target.value},
}))} />
</FormControl>
<FormControl id="state" >
  <FormLabel>{default_language.state}</FormLabel>
  <Input  color={textColor} placeholder={default_language.state} borderRadius="16px" value={userData?.address?.state}  onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, state:event.target.value},
}))} />
</FormControl>
<FormControl id="postalCode" >
  <FormLabel>{default_language.postal_code}</FormLabel>
  <Input color={textColor} placeholder={default_language.postal_code} borderRadius="16px" value={userData?.address?.postalCode} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, postalCode:event.target.value},
}))} />
</FormControl>


<FormControl id="school" >
  <FormLabel>School</FormLabel>
  <Input color={textColor} placeholder={"School"} borderRadius="16px" value={userData?.school} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    school: event.target.value,
}))} />
</FormControl>


<FormControl id="caa" >
  <FormLabel>CAA</FormLabel>
  <Input color={textColor} placeholder={"CAA"} borderRadius="16px" value={userData?.caa} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    caa: event.target.value,
}))} />
</FormControl>


<FormControl id="password" >
  <FormLabel>Password</FormLabel>
  <Input color={textColor} placeholder={default_language.password} borderRadius="16px" value={userData?.address?.password} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    password:event.target.value,
}))} />
</FormControl>






</VStack>
<Button colorScheme="brand" color="white" mt={5} loadingText="....updating" isLoading={isLoading} onClick={()=>handleUpdateClick()}>
Update Profile
</Button>
</Box>
<Box>

  </Box>
       


       

   

  
        
    
    </Box>
  );
}
