import React from 'react';
import { Button, HStack, Box, Text } from '@chakra-ui/react';

const Pagination = ({ totalPages, totalResults, currentPage, onPageChange }) => {
  // Determine if we should disable the previous and next buttons
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages;

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <HStack spacing={4} mt={4} justifyContent="center" alignItems="center">
      <Button
        onClick={() => handlePageChange(currentPage - 1)}
        isDisabled={isPrevDisabled}
      >
        Previous
      </Button>

      {/* Display page numbers */}
      {Array.from({ length: totalPages }, (_, index) => (
        <Button
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          colorScheme={currentPage === index + 1 ? 'blue' : 'gray'}
        >
          {index + 1}
        </Button>
      ))}

      <Button
        onClick={() => handlePageChange(currentPage + 1)}
        isDisabled={isNextDisabled}
      >
        Next
      </Button>

      {/* Display total results */}
      <Box ml={4}>
        <Text>
          Showing page {currentPage} of {totalPages} ({totalResults} results)
        </Text>
      </Box>
    </HStack>
  );
};

export default Pagination;
