module.exports = {
name:"SavvyAtor",
stripe_public_Key:"",
backend_api_url:"https://api.savvyator.com/api/",
images_url:"https://api.savvyator.com/image/",
copyright_text_admin:"SavvyAtor. All Rights Reserved. Made with love by",
made_by:"Mediaheap OU",
made_by_link:"https://www.mediaheap.com",
email:"info@savvyator.com"


}