/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import UsersTable from "views/admin/users/components/UsersTable";
import {
  
  usersColumnsData,

} from "views/admin/users/variables/columnsData";
import tableDataDevelopment from "views/admin/users/variables/tableDataDevelopment.json";
import React, {useState, useEffect} from "react";
import Helpers from "helpers";

export default function Users(props) {
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  useEffect(()=>{

  Helpers.getUsers(page, searchValue).then((response)=>{
    if(response.users)
    {
      setUsers(response.users);
      setTotalPages(response.totalPages);
    }
   

  });


  }, [page, searchValue]);


  

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <UsersTable
          columnsData={usersColumnsData}
          tableData={users}
          title={props.default_language.users}
          onSearch={(value)=>setSearchValue(value)}
          totalPages={totalPages}
          currentPage= {page}
        />
        
       
      </SimpleGrid>
    </Box>
  );
}
