import config from 'config/default';
const isTokenExpired =  () => {
    const expiryTime = localStorage.getItem('expiry');
    if (!expiryTime) {
    return true; // No expiry set, consider it expired for demonstration
  }

  const now = Date.now();
  return now > Number(expiryTime);
};

const getImageURL =   (id) => {
 
 return  config.images_url+"/"+id;
};

const getAuthToken = () => {
    const token = localStorage.getItem('authToken');

    const expiryTime = localStorage.getItem('expiry');
    return token;
    if (!token) {
    return ""; // No expiry set, consider it expired for demonstration
  }

  const now = Date.now();

  if(Number(expiryTime) > now)
  {
    return token;
  }
  else{
    return "";
  }
  return "";

};

const toggleNextQuestions = async() => {
  const nextQuestion = localStorage.getItem('autoNextQuestion');
  localStorage.setItem('autoNextQuestion', nextQuestion == "true"? "false":"true");





};

const getNextQuestionsSettings = () =>{
  const nextQuestion = localStorage.getItem('autoNextQuestion');
  return nextQuestion == "true" ? true : false;


}
const dateWithinLast15Days = (timestamp) => {
  const date = new Date(timestamp);
  const currentDate = new Date();
  const fifteenDaysAgo = new Date(currentDate);
  fifteenDaysAgo.setDate(currentDate.getDate() - 15);

  return date > fifteenDaysAgo && date <= currentDate;
}


const  formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const setAuthToken = (token) => {
//const expiry = Date.now() + 60 * 60 * 1000; // One hour in milliseconds
const expiry = Date.now() + 30 * 60 * 60 * 1000; // One hour in milliseconds

localStorage.setItem('authToken', token); // Replace 'authToken' with your desired key

localStorage.setItem('expiry', expiry);

};
const getStorage = (key) => {
  
  
  return  localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) :null; // Replace 'authToken' with your desired key
  
  
  };
  const setStorage = (key, value) => {
    //const expiry = Date.now() + 60 * 60 * 1000; // One hour in milliseconds
    const expiry = Date.now() + 30 * 60 * 60 * 1000; // One hour in milliseconds
    
    localStorage.setItem(key, JSON.stringify(value)); // Replace 'authToken' with your desired key
    return value;
    
    };
const logout = (token) => {
  //const expiry = Date.now() + 60 * 60 * 1000; // One hour in milliseconds
  
  localStorage.removeItem('authToken'); // Replace 'authToken' with your desired key
  
  localStorage.removeItem('expiry');
  
  };



const loginFunc = async (email, password) => {
   

    const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'User-Agent': 'insomnia/8.6.1'},
        body: JSON.stringify({email:email, password:password })
        };
        try 
        {
        var response = await fetch(config.backend_api_url+'users/login', options);
        if(response)
        {
            response = response.json();
            return response;
        }

        }
        
    catch(err)   {
          return err;


        };
    
    };


    const createAccount = async (email, password, firstName, lastName) => {
   

      const options = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'User-Agent': 'insomnia/8.6.1'},
          body: JSON.stringify({email:email, password:password, firstName:firstName, lastName:lastName, username:email })
          };
          try 
          {
          var response = await fetch(config.backend_api_url+'users', options);
          if(response)
          {
              response = response.json();
              return response;
          }
  
          }
          
      catch(err)   {
            return err;
  
  
          };
      
      };

    const updateUser = async (userData) => {
   
      const authToken =  getAuthToken();

      const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'insomnia/8.6.1',
            Authorization: 'Bearer '+authToken
          },
          body: JSON.stringify(userData)
          };
          try 
          {
          var response = await fetch(config.backend_api_url+'users/'+userData._id, options);
          if(response)
          {
              response = response.json();
              return response;
          }
  
          }
          
      catch(err)   {
            return err;
  
  
          };
      
      };

      const updateProfile = async (userData) => {
   
        const authToken =  getAuthToken();
  
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'User-Agent': 'insomnia/8.6.1',
              Authorization: 'Bearer '+authToken
            },
            body: JSON.stringify(userData)
            };
            try 
            {
            var response = await fetch(config.backend_api_url+'profile', options);
            if(response)
            {
                response = response.json();
                return response;
            }
    
            }
            
        catch(err)   {
              return err;
    
    
            };
        
        };


      const addNewQuestion = async () => {
   
        const authToken =  getAuthToken();
  
        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'User-Agent': 'insomnia/8.6.1',
              Authorization: 'Bearer '+authToken
            },
            body: JSON.stringify({"status":"draft", "text":" ", "options":[{"id":"a", "text":""}, {"id":"b", "text":""}, {"id":"c", "text":""}, {"id":"d", "text":""}]})
            };
            try 
            {
            var response = await fetch(config.backend_api_url+'questions', options);
            if(response)
            {
                response = response.json();
                return response;
            }
    
            }
            
        catch(err)   {
              return err;
    
    
            };
        
        };

        const aiTweak = async (imageurl) => {
   
          const authToken =  getAuthToken();
    
          const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'User-Agent': 'insomnia/8.6.1',
                Authorization: 'Bearer '+authToken
              },
              body: JSON.stringify({"imageUrl":imageurl})
              };
              try 
              {
              var response = await fetch(config.backend_api_url+'process-image', options);
              if(response)
              {
                  response = response.json();
                  return response;
              }
      
              }
              
          catch(err)   {
                return err;
      
      
              };
          
          };

          const createStripeSession = async (userId, priceId, productId) => {
   
            const authToken =  getAuthToken();
      
            const options = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'User-Agent': 'insomnia/8.6.1',
                  Authorization: 'Bearer '+authToken
                },
                body: JSON.stringify({"priceId":priceId, "userId":userId, "productId":productId})
                };
                try 
                {
                var response = await fetch(config.backend_api_url+'stripe-session', options);
                if(response)
                {
                  response = response.json();
                  return response;
                }
        
                }
                
            catch(err)   {
                  return err;
        
        
                };
            
            };


          
          const contrastTweak = async (imagepath) => {
   
            const authToken =  getAuthToken();
      
            const options = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'User-Agent': 'insomnia/8.6.1',
                  Authorization: 'Bearer '+authToken
                },
                body: JSON.stringify({"fileName":imagepath})
                };
                try 
                {
                var response = await fetch(config.backend_api_url+'process-image-contrast', options);
                if(response)
                {
                    response = response.json();
                    return response;
                }
        
                }
                
            catch(err)   {
                  return err;
        
        
                };
            
            };

            const colorsTweak = async (imagepath) => {
   
              const authToken =  getAuthToken();
        
              const options = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': 'insomnia/8.6.1',
                    Authorization: 'Bearer '+authToken
                  },
                  body: JSON.stringify({"fileName":imagepath})
                  };
                  try 
                  {
                  var response = await fetch(config.backend_api_url+'process-image-colors', options);
                  if(response)
                  {
                      response = response.json();
                      return response;
                  }
          
                  }
                  
              catch(err)   {
                    return err;
          
          
                  };
              
              };

      const uploadFile = async (selectedFile) => {
        const authToken =  getAuthToken();

        if (selectedFile) {
            var formData = new FormData();
            formData.append('file', selectedFile);
            console.log(formData.get("file"));

            try {
                // Make a POST request to the upload endpoint
                var response = await fetch(config.backend_api_url+'upload', {
                    method: 'POST',
                    body: formData,
                    headers: {
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    },
                });
                if(response)
                {
                  response = response.json();
                }
                if (response) {
                  return response;

                    // File uploaded successfully
                    console.log('File uploaded successfully');
                } else {

                  return {success:false};

                    // Handle upload error
                    console.error('Failed to upload file');
                }
            } catch (error) {
              return error;
                // Handle fetch error
                console.error('Error uploading file:', error);
            }
        } else {
            // No file selected
            console.error('No file selected');
        }
    };

      const updateQuestion = async (questionData) => {
        console.log(questionData);
   
        const authToken =  getAuthToken();

        if(questionData.explanation_images)
        {
          questionData.explanation.images = questionData.explanation_images;
        }
        console.log(questionData);

        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'User-Agent': 'insomnia/8.6.1',
              Authorization: 'Bearer '+authToken
            },
            body: JSON.stringify(questionData)
            };
            try 
            {
            var response = await fetch(config.backend_api_url+'questions/'+ questionData._id, options);
            if(response)
            {
                response = response.json();
                return response;
            }
    
            }
            
        catch(err)   {
              return err;
    
    
            };
        
        };



        const markMessageAsSeen = async (messageId) => {
     
          const authToken =  getAuthToken();
  
         
  
          const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'User-Agent': 'insomnia/8.6.1',
                Authorization: 'Bearer '+authToken
              },
            
              };
              try 
              {
              var response = await fetch(config.backend_api_url+'messageSeen/'+ messageId, options);
              if(response)
              {
                  response = response.json();
                  return response;
              }
      
              }
              
          catch(err)   {
                return err;
      
      
              };
          
          };

          const sendMessage = async (messageText) => {
     
            const authToken =  getAuthToken();
    
           
    
            const options = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'User-Agent': 'insomnia/8.6.1',
                  Authorization: 'Bearer '+authToken
                },
                body:JSON.stringify({messageText: messageText})
              
                };
                try 
                {
                var response = await fetch(config.backend_api_url+'messages', options);
                if(response)
                {
                    response = response.json();
                    return response;
                }
        
                }
                
            catch(err)   {
                  return err;
        
        
                };
            
            };


        const updatePlan = async (planData) => {
          console.log(planData);
     
          const authToken =  getAuthToken();
  
     
          
          console.log(planData);
  
          const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'User-Agent': 'insomnia/8.6.1',
                Authorization: 'Bearer '+authToken
              },
              body: JSON.stringify(planData)
              };
              try 
              {
              var response = await fetch(config.backend_api_url+'plans/'+ planData._id, options);
              if(response)
              {
                  response = response.json();
                  return response;
              }
      
              }
              
          catch(err)   {
                return err;
      
      
              };
          
          };

        const addStudy = async (study) => {
          console.log(study);
     
          const authToken =  getAuthToken();
    
          const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'User-Agent': 'insomnia/8.6.1',
                Authorization: 'Bearer '+authToken
              },
              body: JSON.stringify(study)
              };
              try 
              {
              var response = await fetch(config.backend_api_url+'studies', options);
              if(response)
              {
                  response = response.json();
                  return response;
              }
      
              }
              
          catch(err)   {
                return err;
      
      
              };
          
          };


          const addFlag = async (flag) => {
            console.log(flag);
       
            const authToken =  getAuthToken();
      
            const options = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'User-Agent': 'insomnia/8.6.1',
                  Authorization: 'Bearer '+authToken
                },
                body: JSON.stringify(flag)
                };
                try 
                {
                var response = await fetch(config.backend_api_url+'flags', options);
                if(response)
                {
                    response = response.json();
                    return response;
                }
        
                }
                
            catch(err)   {
                  return err;
        
        
                };
            
            };



            const addTest = async (data) => {
              console.log("we are trying to create the test with ", data);
         
              const authToken =  getAuthToken();
              
        
              const options = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': 'insomnia/8.6.1',
                    Authorization: 'Bearer '+authToken
                  },
                  body: JSON.stringify(data)
                  };
                  try 
                  {
                  var response = await fetch(config.backend_api_url+'/tests', options);
                  if(response)
                  {
                      response = response.json();

                      console.log("test created . data are", response);

                      return response;
                  }
          
                  }
                  
              catch(err)   {
                    return err;
          
          
                  };
              
              };


              const updateTest = async (testId, answerResult) => {
                console.log(answerResult);
           
                const authToken =  getAuthToken();
          
                const options = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    },
                    body: JSON.stringify(answerResult)
                    };
                    try 
                    {
                    var response = await fetch(config.backend_api_url+'tests/'+testId, options);
                    if(response)
                    {
                        response = response.json();
                        return response;
                    }
            
                    }
                    
                catch(err)   {
                      return err;
            
            
                    };
                
                };


            const addComment = async (comment) => {
              console.log(comment);
         
              const authToken =  getAuthToken();
        
              const options = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': 'insomnia/8.6.1',
                    Authorization: 'Bearer '+authToken
                  },
                  body: JSON.stringify(comment)
                  };
                  try 
                  {
                  var response = await fetch(config.backend_api_url+'comments', options);
                  if(response)
                  {
                      response = response.json();
                      return response;
                  }
          
                  }
                  
              catch(err)   {
                    return err;
          
          
                  };
              
              };


              const updateComment = async (comment) => {
                console.log(comment);
           
                const authToken =  getAuthToken();
          
                const options = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    },
                    body: JSON.stringify(comment)
                    };
                    try 
                    {
                    var response = await fetch(config.backend_api_url+'comments/'+comment._id, options);
                    if(response)
                    {
                        response = response.json();
                        return response;
                    }
            
                    }
                    
                catch(err)   {
                      return err;
            
            
                    };
                
                };


            const deleteFlag = async (flagId) => {
              //console.log(flag);
         
              const authToken =  getAuthToken();
        
              const options = {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': 'insomnia/8.6.1',
                    Authorization: 'Bearer '+authToken
                  },
                  //body: JSON.stringify(flag)
                  };
                  try 
                  {
                  var response = await fetch(config.backend_api_url+'flags/'+flagId, options);
                  if(response)
                  {
                      response = response.json();
                      return response;
                  }
          
                  }
                  
              catch(err)   {
                    return err;
          
          
                  };
              
              };


              const deleteAllFlags = async () => {
                //console.log(flag);
           
                const authToken =  getAuthToken();
          
                const options = {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    },
                    //body: JSON.stringify(flag)
                    };
                    try 
                    {
                    var response = await fetch(config.backend_api_url+'flags', options);
                    if(response)
                    {
                        response = response.json();
                        return response;
                    }
            
                    }
                    
                catch(err)   {
                      return err;
            
            
                    };
                
                };

              const deleteTest = async (testId) => {
                //console.log(flag);
           
                const authToken =  getAuthToken();
          
                const options = {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    },
                    //body: JSON.stringify(flag)
                    };
                    try 
                    {
                    var response = await fetch(config.backend_api_url+'tests/'+testId, options);
                    if(response)
                    {
                        response = response.json();
                        return response;
                    }
            
                    }
                    
                catch(err)   {
                      return err;
            
            
                    };
                
                };


              const deleteComment = async (commentId) => {
                //console.log(flag);
           
                const authToken =  getAuthToken();
          
                const options = {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    },
                    //body: JSON.stringify(flag)
                    };
                    try 
                    {
                    var response = await fetch(config.backend_api_url+'comments/'+commentId, options);
                    if(response)
                    {
                        response = response.json();
                        return response;
                    }
            
                    }
                    
                catch(err)   {
                      return err;
            
            
                    };
                
                };
  


    const getProfileData = async () => {
        const authToken =  getAuthToken();
        console.log(authToken);
        const options = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
             // 'User-Agent': 'insomnia/8.6.1',
              Authorization: 'Bearer '+authToken
            }
          };
   


            try 
            {
            var response = await fetch(config.backend_api_url+'profile', options);
            if(response)
            {
                response = response.json();
                return response;
            }
    
            }
            
          catch(err)   {
              return err;
    
    
            };
        
        };
    
        const getUsers = async (page = 1, search = '') => {
            const authToken =  getAuthToken();
            console.log(authToken);
            const options = {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer '+authToken
                }
              };

              var queryParams = {
                        
              };
              if(page)
              {
                  queryParams.page = page;
              }
              if(search)
              {
                queryParams.search = search;

              }
       
    
    
                try 
                {
                var response = await fetch(config.backend_api_url+'users'+'?'+ new URLSearchParams(queryParams), options);
                if(response)
                {
                    response = response.json();
                    return response;
                }
        
                }
                
              catch(err)   {
                  return err;
        
        
                };
            
            };


            
            


            const getRoles = async () => {
              const authToken =  getAuthToken();
              console.log(authToken);
              const options = {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer '+authToken
                  }
                };
  
               
         
      
      
                  try 
                  {
                  var response = await fetch(config.backend_api_url+'roles', options);
                  if(response)
                  {
                      response = response.json();
                      return response;
                  }
          
                  }
                  
                catch(err)   {
                    return err;
          
          
                  };
              
              };

            const getUser = async (userId = '') => {
              const authToken =  getAuthToken();
              console.log(authToken);
              const options = {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': 'insomnia/8.6.1',
                    Authorization: 'Bearer '+authToken
                  }
                };
  
                var queryParams = {
                          
                };
               
         
      
      
                  try 
                  {
                  var response = await fetch(config.backend_api_url+'users/'+ userId, options);
                  if(response)
                  {
                      response = response.json();
                      return response;
                  }
          
                  }
                  
                catch(err)   {
                    return err;
          
          
                  };
              
              };

              const getQuestion = async (questionId = '') => {
                const authToken =  getAuthToken();
                console.log(authToken);
                const options = {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    }
                  };
    
                  var queryParams = {
                            
                  };
                 
           
        
        
                    try 
                    {
                    var response = await fetch(config.backend_api_url+'questions/'+ questionId, options);
                    if(response)
                    {
                        response = response.json();
                        return response;
                    }
            
                    }
                    
                  catch(err)   {
                      return err;
            
            
                    };
                
                };


                const getTest = async (testId = '') => {
                  const authToken =  getAuthToken();
                  console.log(authToken);
                  const options = {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                      
                        Authorization: 'Bearer '+authToken
                      }
                    };
      
                    var queryParams = {
                              
                    };
                   
             
          
          
                      try 
                      {
                      var response = await fetch(config.backend_api_url+'tests/'+ testId, options);
                      if(response)
                      {
                          response = response.json();
                          return response;
                      }
              
                      }
                      
                    catch(err)   {
                        return err;
              
              
                      };
                  
                  };

                  const getTests = async () => {
                    const authToken =  getAuthToken();
                    console.log(authToken);
                    const options = {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          'User-Agent': 'insomnia/8.6.1',
                          Authorization: 'Bearer '+authToken
                        }
                      };
        
                      var queryParams = {
                                
                      };
                     
               
            
            
                        try 
                        {
                        var response = await fetch(config.backend_api_url+'tests', options);
                        if(response)
                        {
                            response = response.json();
                            return response;
                        }
                
                        }
                        
                      catch(err)   {
                          return err;
                
                
                        };
                    
                    };


                  const getDashboard = async () => {
                    const authToken =  getAuthToken();
                    console.log(authToken);
                    const options = {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          'User-Agent': 'insomnia/8.6.1',
                          Authorization: 'Bearer '+authToken
                        }
                      };
        
                      var queryParams = {
                                
                      };
                     
               
            
            
                        try 
                        {
                        var response = await fetch(config.backend_api_url+'dashboard', options);
                        if(response)
                        {
                            response = response.json();
                            return response;
                        }
                
                        }
                        
                      catch(err)   {
                          return err;
                
                
                        };
                    
                    };


                const getPlan = async (planId = '') => {
                  const authToken =  getAuthToken();
                  console.log(authToken);
                  const options = {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'User-Agent': 'insomnia/8.6.1',
                        Authorization: 'Bearer '+authToken
                      }
                    };
      
                    var queryParams = {
                              
                    };
                   
             
          
          
                      try 
                      {
                      var response = await fetch(config.backend_api_url+'plans/'+ planId, options);
                      if(response)
                      {
                          response = response.json();
                          return response;
                      }
              
                      }
                      
                    catch(err)   {
                        return err;
              
              
                      };
                  
                  };

                const getNextPrevQuestions = async (questionId, page = getStorage('page'), search = getStorage('searchValue'), study = getStorage('study'), subject = getStorage('subject'), withRealExams = getStorage('withRealExams'), ownQuestions = getStorage('ownQuestions'), status = getStorage('status'), searchLocation = getStorage('searchLocationValue')) => {
                  const authToken =  getAuthToken();
                  console.log(authToken);
                  const options = {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'User-Agent': 'insomnia/8.6.1',
                        Authorization: 'Bearer '+authToken
                      }
                    };

                    var queryParams = {
                        questionId:questionId
                  };
                  if(page)
            {
               // queryParams.page = page;
            }
            if(search)
            {
              queryParams.search = search;

            }
            if(status)
            {
              queryParams.status = status;

            }
            if(searchLocation)
            {
              queryParams.searchLocation = searchLocation;

            }
            
          
                  if(study)
                  {
                      queryParams.study = study;
                  }
                  if(subject)
                  {
                      queryParams.subject = subject;
                  }
                  if(withRealExams !=null)
                  {
                    if(withRealExams)
                    {
                      queryParams.realExams = withRealExams;

                    }

                  }

                  if(ownQuestions !=null)
                  {
                    if(ownQuestions)
                    {
                      queryParams.ownQuestions = true;

                    }

                  }
          
          
                      try 
                      {
                      var response = await fetch(config.backend_api_url+'nextprevquestions'+ '?' + new URLSearchParams(queryParams).toString(), options);
                      if(response)
                      {
                          response = response.json();
                          return response;
                      }
              
                      }
                      
                    catch(err)   {
                        return err;
              
              
                      };
                  
                  };

            const getPlans = async () => {
                const authToken =  getAuthToken();
                console.log(authToken);
                const options = {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'User-Agent': 'insomnia/8.6.1',
                      Authorization: 'Bearer '+authToken
                    }
                  };
           
        
        
                    try 
                    {
                    var response = await fetch(config.backend_api_url+'plans', options);
                    if(response)
                    {
                        response = response.json();
                        return response;
                    }
            
                    }
                    
                  catch(err)   {
                      return err;
            
            
                    };
                
                };

                const getPlansPublic = async () => {
                  const authToken =  getAuthToken();
                  console.log(authToken);
                  const options = {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'User-Agent': 'insomnia/8.6.1',
                        Authorization: 'Bearer '+authToken
                      }
                    };
             
          
          
                      try 
                      {
                      var response = await fetch(config.backend_api_url+'plans_public', options);
                      if(response)
                      {
                          response = response.json();
                          return response;
                      }
              
                      }
                      
                    catch(err)   {
                        return err;
              
              
                      };
                  
                  };

                const getFlags = async (questionId) => {
                  const authToken =  getAuthToken();
                  console.log(authToken);
                  const options = {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'User-Agent': 'insomnia/8.6.1',
                        Authorization: 'Bearer '+authToken
                      }
                    };
             
          
          
                      try 
                      {
                      var response = await fetch(config.backend_api_url+'flags/question'+ '/' + questionId, options);
                      if(response)
                      {
                          response = response.json();
                          return response;
                      }
              
                      }
                      
                    catch(err)   {
                        return err;
              
              
                      };
                  
                  };

                  const getAllFlags = async (params) => {
                    const authToken =  getAuthToken();
                    console.log(authToken);
                    const options = {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          'User-Agent': 'insomnia/8.6.1',
                          Authorization: 'Bearer '+authToken
                        }
                      };
               
            
            
                        try 
                        {
                        var response = await fetch(config.backend_api_url+'flags?'+new URLSearchParams(params).toString(), options);
                        if(response)
                        {
                            response = response.json();
                            return response;
                        }
                
                        }
                        
                      catch(err)   {
                          return err;
                
                
                        };
                    
                    };

                  const getCommentsForQuestion = async (questionId) => {
                    const authToken =  getAuthToken();
                    console.log(authToken);
                    const options = {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          'User-Agent': 'insomnia/8.6.1',
                          Authorization: 'Bearer '+authToken
                        }
                      };
               
            
            
                        try 
                        {
                        var response = await fetch(config.backend_api_url+'comments/question'+ '/' + questionId, options);
                        if(response)
                        {
                            response = response.json();
                            return response;
                        }
                
                        }
                        
                      catch(err)   {
                          return err;
                
                
                        };
                    
                    };


                    const getMessages = async (page = 1) => {
                      const authToken =  getAuthToken();
                      console.log(authToken);
                      const options = {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            'User-Agent': 'insomnia/8.6.1',
                            Authorization: 'Bearer '+authToken
                          }
                        };
                 
                        var queryParams = {
                        
                        };
                        if(page)
                  {
                      queryParams.page = page;
                  }
              
                          try 
                          {
                          var response = await fetch(config.backend_api_url+'messages' + '?' + new URLSearchParams(queryParams).toString(), options);
                          if(response)
                          {
                              response = response.json();
                              return response;
                          }
                  
                          }
                          
                        catch(err)   {
                            return err;
                  
                  
                          };
                      
                      };


                      const getMessagesAdmin = async (page = 1) => {
                        const authToken =  getAuthToken();
                        console.log(authToken);
                        const options = {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json',
                              'User-Agent': 'insomnia/8.6.1',
                              Authorization: 'Bearer '+authToken
                            }
                          };
                          var queryParams = {
                        
                          };
                          if(page)
                    {
                        queryParams.page = page;
                    }
                
                
                            try 
                            {
                            var response = await fetch(config.backend_api_url+'messagesadmin'+ '?' + new URLSearchParams(queryParams).toString(), options);
                            if(response)
                            {
                                response = response.json();
                                return response;
                            }
                    
                            }
                            
                          catch(err)   {
                              return err;
                    
                    
                            };
                        
                        };

                const getQuestions = async (page = 1, search = '', study = '', subject = '', withRealExams = null, ownQuestions = null, status = null, searchLocation = null) => {
                    const authToken =  getAuthToken();
                    console.log(authToken);
                    const options = {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          'User-Agent': 'insomnia/8.6.1',
                          Authorization: 'Bearer '+authToken
                        }
                      };

                      var queryParams = {
                        
                    };
                    if(page)
              {
                  queryParams.page = page;
              }
              if(search)
              {
                queryParams.search = search;


              }
              if(status)
              {
                queryParams.status = status;

              }
              if(searchLocation)
              {
                queryParams.searchLocation = searchLocation;

              }
              
            
                    if(study)
                    {
                        queryParams.study = study;
                    }
                    if(subject)
                    {
                        queryParams.subject = subject;
                    }
                    if(withRealExams !=null)
                    {
                      if(withRealExams)
                      {
                        queryParams.realExams = withRealExams;

                      }

                    }

                    if(ownQuestions !=null)
                    {
                      if(ownQuestions)
                      {
                        queryParams.ownQuestions = true;

                      }

                    }
            
            
                        try 
                        {
                        var response = await fetch(config.backend_api_url+'questions'+ '?' + new URLSearchParams(queryParams).toString().replace(/\+/g, '%20')
                        
                        , options);
                        if(response)
                        {
                            response = response.json();
                            return response;
                        }
                
                        }
                        
                      catch(err)   {
                          return err;
                
                
                        };
                    
                    };


                    


                    const getSubjects = async (study = '', parent='') => {
                        const authToken =  getAuthToken();
                        console.log(authToken);
                        const options = {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json',
                              'User-Agent': 'insomnia/8.6.1',
                              Authorization: 'Bearer '+authToken
                            }
                          };
    
                          var queryParams = {
                           
                        };
                        if(study)
                        {
                            queryParams.study = study;
                        }
                        if(parent)
                        {
                          queryParams.parent = parent;
                        }
                      
                
                
                            try 
                            {
                            var response = await fetch(config.backend_api_url+'subjects'+ '?' + new URLSearchParams(queryParams), options);
                            if(response)
                            {
                                response = response.json();
                                return response;
                            }
                    
                            }
                            
                          catch(err)   {
                              return err;
                    
                    
                            };
                        
                        };



                        const getStudies = async (study = '') => {
                            const authToken =  getAuthToken();
                            console.log(authToken);
                            const options = {
                                method: 'GET',
                                headers: {
                                  'Content-Type': 'application/json',
                                  'User-Agent': 'insomnia/8.6.1',
                                  Authorization: 'Bearer '+authToken
                                }
                              };
        
                        
                    
                    
                                try 
                                {
                                var response = await fetch(config.backend_api_url+'studies', options);
                                if(response)
                                {
                                    response = response.json();
                                    return response;
                                }
                        
                                }
                                
                              catch(err)   {
                                  return err;
                        
                        
                                };
                            
                            };


                            const getLogs = async (page = 1, search = '', user = null, action= null, date = null, dateTo = null, study = null, subject = null) => {
                              const authToken =  getAuthToken();
                              console.log(authToken);
                              const options = {
                                  method: 'GET',
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'User-Agent': 'insomnia/8.6.1',
                                    Authorization: 'Bearer '+authToken
                                  }
                                };
          
                                var queryParams = {
                                  
                              };
                              if(page)
                        {
                            queryParams.page = page;
                        }
                        if(search)
                        {
                          queryParams.search = search;
          
                        }
                              if(user)
                              {
                                  queryParams.user = user;
                              }
                              if(study)
                              {
                                  queryParams.study = study;
                              }
                              if(subject)
                              {
                                  queryParams.subject = subject;
                              }
                              if(action)
                              {
                                  queryParams.action = action;
                              }
                              if(date)
                              {
                                  queryParams.date = date;
                              }
                              if(dateTo)
                              {
                                  queryParams.dateTo = dateTo;
                              }
                      
                      
                                  try 
                                  {
                                  var response = await fetch(config.backend_api_url+'logs'+ '?' + new URLSearchParams(queryParams), options);
                                  if(response)
                                  {
                                      response = response.json();
                                      return response;
                                  }
                          
                                  }
                                  
                                catch(err)   {
                                    return err;
                          
                          
                                  };
                              
                              };



export default {
  uploadFile,
  dateWithinLast15Days,
  sendMessage,
  formatTimestamp,
  isTokenExpired,
  addNewQuestion,
   getAuthToken,
   setAuthToken,
   loginFunc,
   getImageURL,
   addTest,
   getTest,
   aiTweak,
   getTests,
   getProfileData,
   createStripeSession,
   getUsers,
   getPlans,
   getFlags,
   deleteFlag,
   deleteComment,
   getAllFlags,
   updateComment,
   addComment,
   markMessageAsSeen,
   addFlag,
   getLogs,
   getQuestions,
   getPlan,
   logout,
   contrastTweak,
   updateQuestion,
   deleteAllFlags,
   updatePlan,
   getQuestion,
   getMessages,
   getStudies,
   getSubjects,
   updateTest,
   getNextPrevQuestions,
   getDashboard,
   getUser,
   updateUser,
   updateProfile,
   getRoles,
   createAccount,
   getCommentsForQuestion,
   addStudy,
   toggleNextQuestions,
   getNextQuestionsSettings,
   getPlansPublic,
   getMessagesAdmin,
   colorsTweak,
   deleteTest,
   getStorage,
   setStorage

}

