import React from 'react';
import {
  Box,
  Button,
  VStack,
  Heading,
  Text,
  Badge,
  List,
  ListItem,
  Stack,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import Helpers from '../../../../helpers';

const PlansLayout = ({ plans, profileData }) => {
  // Define color values for light and dark mode
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('black', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const buttonColorScheme = useColorModeValue('blue', 'teal');

  const handlePurchase = async (plan) => {
    const purchaseLink = await Helpers.createStripeSession(
      profileData._id,
      plan.stripePriceId,
      plan.stripeProductId
    );
    window.location.href = purchaseLink.url;
  };

  return (
    <Box p={8} color={textColor}>
      <Heading mb={8} textAlign="center">
        Available Plans
      </Heading>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={8} justifyContent="center">
        {plans.map((plan) => (
          <Flex
            key={plan._id}
            borderWidth="1px"
            borderRadius="lg"
            p={6}
            bg={bgColor}
            boxShadow="lg"
            w={{ base: 'full', md: '300px' }}
            direction="column"
            borderColor={borderColor}
          >
            <VStack spacing={4} align="start" flexGrow={1}>
              <Heading size="md" color={textColor}>
                {plan.name}
              </Heading>

              <Text color={textColor}>
                Price: ${plan.price} / {plan.interval}
              </Text>

              {plan.sale_price && (
                <Text color="green.500">
                  Sale Price: ${plan.sale_price}
                </Text>
              )}

              {plan.trial && (
                <Badge colorScheme="green">
                  {plan.trialDays} Days Free Trial
                </Badge>
              )}

              <List spacing={2}>
                {plan.features.map((feature, index) => (
                  <ListItem key={index}>✔ {feature}</ListItem>
                ))}
              </List>
            </VStack>

            {/* Keep the button always at the bottom */}
            <Button
              onClick={() => handlePurchase(plan)}
              colorScheme={buttonColorScheme}
              width="full"
              mt="auto" // Pushes the button to the bottom of the card
              target="_blank"
            >
              Purchase Plan
            </Button>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default PlansLayout;
