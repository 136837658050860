import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import UserLayout from 'layouts/user';

import HomeLayout from 'layouts/home';

import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import default_language from 'config/default_language';
import default_config from 'config/default';






ReactDOM.render(
	<ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom' } }}> 
		<React.StrictMode>
			<ThemeEditorProvider>
				<HashRouter>
					<Switch>
							
						<Route path={`/home`} render={() => <HomeLayout default_language={default_language} default_config={default_config} />}  />

						<Route path={`/auth`} render={() => <AuthLayout default_language={default_language} default_config={default_config} />}  />
						<Route path={`/admin`} render={() => <AdminLayout default_language={default_language} default_config={default_config} />}  />
						<Route path={`/user`} render={() => <UserLayout default_language={default_language} default_config={default_config} />}  />

						<Route path={`/rtl`} component={RtlLayout} />

						<Redirect exact from={`/`} to={`/home`} />

					
					</Switch>
				</HashRouter>
			</ThemeEditorProvider>
		</React.StrictMode>
		
	</ChakraProvider>,
	document.getElementById('root')
);
