import React from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  VStack,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaDatabase,
  FaQuestionCircle,
  FaFilter,
  FaUserTie,
  FaClipboardCheck,
  FaComments,
  FaPlane,
} from "react-icons/fa";

const featuresList = [
  {
    icon: FaDatabase,
    title: "Updated Database",
    description: "Stay up-to-date with the latest and most accurate data available.",
  },
  {
    icon: FaQuestionCircle,
    title: "Real Exam Questions",
    description: "Practice with real exam questions to boost your confidence and readiness.",
  },
  {
    icon: FaFilter,
    title: "Multi-Filtering Options",
    description: "Easily filter through content to focus on what matters most to you.",
  },
  {
    icon: FaUserTie,
    title: "Support from Our Trainers",
    description: "Get expert help and guidance from our experienced trainers.",
  },
  {
    icon: FaClipboardCheck,
    title: "Explanations",
    description: "Understand the reasoning behind each answer with detailed explanations.",
  },
  {
    icon: FaComments,
    title: "Access to Forum",
    description: "Join a community of learners and share knowledge in our forums.",
  },
  {
    icon: FaPlane,
    title: "Pilot Assessment",
    description: "Assess your skills and readiness with our comprehensive pilot assessment tools.",
  },
];

const Features = () => {
  // Define color values using useColorModeValue outside of the JSX
  const bg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const iconColor = useColorModeValue('blue.500', 'blue.300');
  const boxShadowHover = useColorModeValue('lg', 'dark-lg');

  return (
    <Box width="100%" maxWidth="1200px" mx="auto" p={6}>
      <Heading textAlign="center" mb={6}>
        Key Features
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {featuresList.map((feature, index) => (
          <Box
            key={index}
            p={6}
            borderWidth={1}
            borderRadius="lg"
            bg={bg}
            textAlign="center"
            boxShadow="md"
            transition="transform 0.3s ease, box-shadow 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: boxShadowHover,
            }}
          >
            <VStack spacing={4}>
              <Icon as={feature.icon} w={10} h={10} color={iconColor} />
              <Text fontSize="xl" fontWeight="bold">
                {feature.title}
              </Text>
              <Text fontSize="md" color={textColor}>
                {feature.description}
              </Text>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Features;
