/* eslint-disable */
import {
    Box,
    Flex,
    Progress,
    Table,
    Tbody,
    Td,
    Button,
    Stack,
    Text,
    Th,
    Thead,
    Tr,Center,
    useColorModeValue,
    InputGroup,
    Input,
    InputLeftElement,
    IconButton,
    SimpleGrid,
    ButtonGroup,
    Select,
    Option,
    Badge,
 Checkbox, 
 ButtonProps,
 CheckboxGroup ,

  } from "@chakra-ui/react";
  import {EmailIcon,  SearchIcon, DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
useHistory
  // Custom components
  import Card from "components/card/Card";
  import Menu from "components/menu/MainMenu";
  import React, { useMemo } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";

  import {
    Paginator,
    Container,
    Previous,
    Next,
    PageGroup,
    usePaginator
  } from "chakra-paginator";
import default_language from "config/default_language";
  import {NavLink, useHistory} from 'react-router-dom';
  import Helpers from "helpers";
  //import Pagination from "react-js-pagination";

  export default function DevelopmentTable(props) {


    const baseStyles =   {
      "w": 10,
      "fontSize": "sm"
    };
  
    const normalStyles =   {
      ...baseStyles,
      _hover: {
        bg: "black",
        color:"white"

      },
      bg: "grey.300"
    };
  
    const activeStyles  = {
      ...baseStyles,
      _hover: {
        bg: "black"
      },
      bg: "black",
      color:"white"
    };



    const { columnsData, tableData } = props;
    const history = useHistory();

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

   const Paginationold = () =>{
        var pagesExport = [];

        for (let i = 1; i <= props.totalPages; i++) {
            pagesExport.push( <Button onClick={()=>props.onPageChange(i)}  variant={i == props.currentPage ? 'brand' : 'outline' } aria-label='Delete' icon={<DeleteIcon /> } >{i}</Button>
            )
        }



        return (pagesExport);
    }

    const addNewQuestionClick = () => {

      Helpers.addNewQuestion().then((response)=>{
        console.log("our response for add new question is");
        console.log(response);
        history.push('/admin/question/'+response._id);

    
      });
      

    }

    


  
    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
    } = tableInstance;
    initialState.pageSize = 11;
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("secondaryGray.500", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
                  <Flex px='25px' justify='space-between' mb='20px' align='center'>
                  <SimpleGrid columns={{ base: 2, md: 2, lg: 2 }} spacing={10}>

             <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'>
            {props.title}
          </Text>

          <Button variant="brand" onClick={()=>addNewQuestionClick()}>{default_language.add_question}</Button>

          

          <InputGroup style={{width:'100%'}}>
    <InputLeftElement pointerEvents='none'>
      <SearchIcon color='gray.300' />
    </InputLeftElement>
    <Input type='text' placeholder={default_language.search_dots} onChange={(event)=>props.onSearch(event.target.value)} value={props.searchValue} />
  </InputGroup>

  <InputGroup style={{width:'100%'}}>
    <Select onChange={(event)=>props.onSearchLocation(event.target.value)} value={props.searchLocation}>
      <option value="">Everywhere</option>
      <option value="id">ID</option>

      <option value="question">Question</option>
      <option value="answers">Answers</option>
      <option value="explanation">Explanation</option>

      </Select>
  </InputGroup>

</SimpleGrid>
<Flex px='25px' justify='space-between' mb='20px' align='center'>
<SimpleGrid columns={{ base: 2, md: 2, lg: 2 }} spacing={10}>

  <span>{default_language.total_pages}:  {props.totalPages}</span>
  <span>{default_language.total_results}:  {props.totalResults}</span>

  </SimpleGrid>
  </Flex>

          </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
     
        <SimpleGrid columns={{ base: 2, md: 5, lg: 5 }} spacing={10}>


        <Select placeholder={default_language.status} onChange={(event)=> props.onChangeStatus(event.target.value)} value={props.status}>

{ props?.statuses && 
  props?.statuses.map((status)=>{
    return (  <option value={status.id}>{status.title}</option>
    )
  })
}

</Select>

        
        
         

  <Select placeholder={default_language.study} onChange={(event)=> props.onChangeStudy(event.target.value)} value={props.study}>

    { props?.studies && 
      props?.studies.map((study)=>{
        return (  <option value={study._id}>{study.title}</option>
        )
      })
    }
 
</Select>


<Select placeholder={default_language.all} onChange={(event)=> props.onChangeSubject(event.target.value)} value={props.subject}>

{ props?.subjects && 
      props?.subjects.map((subject)=>{

        return (  <option value={subject._id}>{subject.title}</option>
        )
      })
    }
</Select>

<Select placeholder={default_language.all} onChange={(event)=> props.onChangeSubSubject(event.target.value)} value={props.subSubject}>

{ props?.subSubjects && 
      props?.subSubjects.map((subject)=>{

        return (  <option value={subject._id}>{subject.title}</option>
        )
      })
    }
</Select>

<Select placeholder={default_language.all} onChange={(event)=> props.onChangeSubSubSubject(event.target.value)} value={props.subSubSubject}>

{ props?.subSubSubjects && 
      props?.subSubSubjects.map((subject)=>{

        return (  <option value={subject._id}>{subject.title}</option>
        )
      })
    }
</Select>


<Checkbox size='md' colorScheme='green' onChange={(event)=>props.onRealExamsChange(event.target.checked)} isChecked={props.withRealExams}>
    {default_language.only_real_exams}
  </Checkbox>

  <Checkbox size='md' colorScheme='green' onChange={(event)=>props.onOwnQuestionsChange(event.target.checked)} isChecked={props.ownQuestions}>
    Only Own Questions
  </Checkbox>

  </SimpleGrid>
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr  {...row.getRowProps()} style={{cursor:'pointer'} } key={index} onClick={()=> history.push('/admin/question/'+row.original._id)} >
                  {row.cells.map((cell, index) => {
                    let data = "";

                    if (cell.column.id === "id") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.internal_id}
                  </Text>
                        
                      );
                    }


                    if (cell.column.id === "user") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.modifiedBy[0]?.user?.email}
                  </Text>
                        
                      );
                    }
                    if (cell.column.id === "text") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value.replace(/<[^>]*>?/gm, '')}
                          <Badge ml="1" colorScheme="green" style={!Helpers.dateWithinLast15Days(cell.row.original.registrationDate ) ? {display:'none'}:{}}>
        New
      </Badge>
                        </Text>
                        
                      );
                    }

                    if (cell.column.id === "createdAt") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {Helpers.formatTimestamp(cell.value)}
                          </Text>
                        );
                      }
                      if (cell.column.id === "modifiedAt") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {Helpers.formatTimestamp(cell.value)}
                          </Text>
                        );
                      } 
                      if (cell.column.id === "actions") {
                        console.log("our data are");
                        console.log(cell.row.original._id);
                        data = (
                            <ButtonGroup size='md' isAttached variant='outline'>
                            <IconButton aria-label='Edit' icon={<EditIcon /> } onClick={()=> history.push('/admin/question/'+cell.row.original._id)}  />


</ButtonGroup>


                        );
                      } 
                  
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        
          
        
        </Table>
        <Center>
        
        <Flex alignContent={'center'} > 

      

      
                
        <Paginator
        pagesQuantity={props.totalPages}
        currentPage={props.currentPage}
        initialState={{currentPage:3}}
        innerLimit={3}
        activeStyles={activeStyles}
        normalStyles={normalStyles}

        outerLimit={5}
       onPageChange={(i)=>props.onPageChange(i)}
      >
        
        <Container align="center" justify="space-between" w="full" p={4}>
          <Previous>
            Previous
            {/* Or an icon from `react-icons` */}
          </Previous>
          <PageGroup isInline align="center" />
          <Next>
            Next
            {/* Or an icon from `react-icons` */}
          </Next>
        </Container>
      </Paginator>


          </Flex>
          </Center>
      </Card>
    );
  }
  