/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

import MessagesComponent  from "./components/Messages";
import { useEffect, useState } from "react"; 
import Helpers from '../../../helpers';
import helpers from "../../../helpers";

export default function Messages(Props) {
  console.log(Props);
  const [messages, setMessages] = useState([]);



  useEffect(()=>{

    loadMessages();
    
  },[])
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBgnew = useColorModeValue("white", "whiteAlpha.100");

  const loadMessages = async() =>
  {

    try{

            const messagesResponse = await Helpers.getMessages();

            if(messagesResponse){
            setMessages(messagesResponse);
            }
    }
    catch(error)
    {
        console.log(error);
    }
    
     
  }


  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }} >
    <MessagesComponent messages={messages} profileData={Props?.profileData} />
     
    </Box>
  );
}
