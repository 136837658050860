/* eslint-disable */
import {
    Box,
    Flex,
    Progress,
    Table,
    Tbody,
    Td,
    Button,
    Text,
    Th,
    Thead,
    Tr,Center,
    useColorModeValue,
    InputGroup,
    Input,
    InputLeftElement,
    IconButton,
    SimpleGrid,
    ButtonGroup,
    Badge
  } from "@chakra-ui/react";
  import {EmailIcon,  SearchIcon, DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
useHistory
  // Custom components
  import Card from "components/card/Card";
  import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
  import Menu from "components/menu/MainMenu";
  import React, { useMemo } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
import default_language from "config/default_language";
  import {NavLink, useHistory} from 'react-router-dom';
  import Helpers from "helpers";
  export default function DevelopmentTable(props) {
    const { columnsData, tableData } = props;
    const history = useHistory();

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

   const Pagination = () =>{
        var pagesExport = [];

        for (let i = 1; i <= props.totalPages; i++) {
            pagesExport.push( <Button  variant={i == props.currentPage ? 'brand' : 'outline' } aria-label='Delete' icon={<DeleteIcon /> } >{i}</Button>
            )
        }



        return (pagesExport);
    }
  
    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
    } = tableInstance;
    initialState.pageSize = 11;
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("secondaryGray.500", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <SimpleGrid columns={2} spacing={10}>


          <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'>
            {props.title}
          </Text>
          <InputGroup style={{width:'100%'}}>
    <InputLeftElement pointerEvents='none'>
      <SearchIcon color='gray.300' />
    </InputLeftElement>
    <Input type='text' placeholder={default_language.search_dots} onChange={(event)=>props.onSearch(event.target.value)} />
  </InputGroup>
  </SimpleGrid>
          <Menu />
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.id === "firstName") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                          <Badge ml="1" colorScheme="green" style={!Helpers.dateWithinLast15Days(cell.row.original.registrationDate ) ? {display:'none'}:{}}>
        New
      </Badge>
                        </Text>
                        
                      );
                    }
                    if (cell.column.id === "lastName") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        );
                      }
                      if (cell.column.id === "email") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        );
                      } 
                      if (cell.column.id === "actions") {
                        console.log("our data are");
                        console.log(cell.row.original._id);
                        data = (
                            <ButtonGroup size='md' isAttached variant='outline'>
                            <IconButton aria-label='View' icon={<ViewIcon /> } />
                            <IconButton aria-label='Edit' icon={<EditIcon /> } onClick={()=> history.push('/admin/user/'+cell.row.original._id)}  />

                            <IconButton aria-label='Email' icon={<EmailIcon /> } />

  <IconButton aria-label='Delete' icon={<DeleteIcon /> } />
</ButtonGroup>


                        );
                      } 
                  
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        
          
        
        </Table>
        <Center>
        <Flex alignContent={'center'} > 
                <ButtonGroup size='md' isAttached variant='outline'>
                    <Pagination/>

    

                </ButtonGroup>


          </Flex>
          </Center>
      </Card>
    );
  }
  