/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import QuestionsTable from "views/admin/questions/components/QuestionsTable";
import {
  
  questionsColumnsData,

} from "views/admin/questions/variables/columnsData";
import tableDataDevelopment from "views/admin/questions/variables/tableDataDevelopment.json";
import React, {useState, useEffect} from "react";
import Helpers from "helpers";
import { usePaginator } from "chakra-paginator";

export default function Questions(props) {
  const [page, setPage] = useState(Helpers.getStorage('page')?? 1);


  const [questions, setQuestions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState(Helpers.getStorage('searchValue'));
  const [searchLocationValue, setSearchLocationValue] = useState(Helpers.getStorage('searchLocationValue'));

  const [study, setStudy] = useState(Helpers.getStorage('study'));
  const [subject, setSubject] = useState(Helpers.getStorage('subject'));
  const [subSubject, setSubSubject] = useState(Helpers.getStorage('subSubject'));
  const [subSubSubject, setSubSubSubject] = useState(Helpers.getStorage('subSubSubject'));
  const [status, setStatus] = useState(Helpers.getStorage('status'));
  const [totalResults, setTotalResults] = useState(0);

  const [studies, setStudies] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [subSubjects, setSubSubjects] = useState([]);
  const [subSubSubjects, setSubSubSubjects] = useState([]);

  const [withRealExams, setWithRealExams] = useState(Helpers.getStorage('withRealExams'));
  const [ownQuestions, setOwnQuestions] = useState(Helpers.getStorage('ownQuestions'));

const statuses = [{id:"active", title:props.default_language.active}, {id:"draft", title:props.default_language.draft}, {id:"pending", title:props.default_language.pending}]
  useEffect(()=>{
    var final_subject = subSubSubject ? subSubSubject : (subSubject ? subSubject: subject) ?? subject;
  Helpers.getQuestions(page, searchValue, study, final_subject, withRealExams, ownQuestions, status, searchLocationValue).then((response)=>{
    setQuestions(response.questions);

      setTotalPages(response.totalPages);

    
    setTotalResults(response.totalResults);

  });

  
    Helpers.setStorage('study', study);
  
    Helpers.setStorage('page', page);
    Helpers.setStorage('searchValue', searchValue);
    Helpers.setStorage('searchLocationValue', searchLocationValue);
    Helpers.setStorage('subject', subject);
    Helpers.setStorage('subSubject', subSubject);
    Helpers.setStorage('subSubSubject', subSubSubject);
    Helpers.setStorage('withRealExams', withRealExams);
    Helpers.setStorage('status', status);
    Helpers.setStorage('ownQuestions', ownQuestions);

  


  }, [page, searchValue, study, subject, subSubject, subSubSubject, withRealExams, status, searchLocationValue, ownQuestions]);


  useEffect(()=>{


  Helpers.getStudies().then((response)=>{
    console.log(response);
    setStudies(response);

  });


 



  }, []);


  useEffect(()=>{

    if(study)
    {
      Helpers.getSubjects(study).then((response)=>{
        console.log("subjects are");
        console.log(response);
        setSubjects(response);
    
      });
    }
    else{

      setSubjects([]);
      setSubSubjects([]);
      setSubSubSubjects([]);

    }

  
  
   
  
  
  
    }, [study]);


    useEffect(()=>{

      if(subject)
      {
        Helpers.getSubjects(study, subject).then((response)=>{
          console.log(response);
          setSubSubjects(response);
      
        });

     

      }
      else{

        setSubSubjects([]);
        setSubSubSubjects([]);
  
      }
  
  
    
    
     
    
    
    
      }, [subject]);


      useEffect(()=>{

        if(subSubject)
        {
          Helpers.getSubjects(study, subSubject).then((response)=>{
            console.log(response);
            setSubSubSubjects(response);
        
          });
        }
        else{

          setSubSubSubjects([]);
    
        }
    
    
      
      

      
      
      
        }, [subSubject]);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <QuestionsTable
          columnsData={questionsColumnsData}
          tableData={questions}
          title={props.default_language.questions}
          onSearch={(value)=> {setSearchValue(value); setPage(1);setSubject(""); setSubSubject(""); setSubSubSubject("");  }}
          onChangeStudy={(value)=> {setStudy(value); setPage(1); }}
          onChangeStatus={(value)=> {setStatus(value); setPage(1); }}
          onSearchLocation={(value)=> {setSearchLocationValue(value); setPage(1);setSubject(""); setSubSubject(""); setSubSubSubject("");  }}
          searchValue={searchValue}
          searchLocation={searchLocationValue}
          onChangeSubject={(value)=> {setSubject(value); setPage(1); setSubSubject(""); setSubSubSubject(""); }}
          onChangeSubSubject={(value)=> {setSubSubject(value); setPage(1);  setSubSubSubject(""); }}
          onChangeSubSubSubject={(value)=> {setSubSubSubject(value); setPage(1); }}
          subjects={subjects}
          subject={subject}
          subSubject={subSubject}
          subSubSubject={subSubSubject}
          study={study}
          studies={studies}
          totalResults={totalResults}
          statuses={statuses}
          status={status}
          subSubjects={subSubjects}
          subSubSubjects = {subSubSubjects}
          totalPages={totalPages}
          currentPage={page}
          ownQuestions={ownQuestions}
          onPageChange={(value)=> {setPage(value) }}
          withRealExams={withRealExams}
          onRealExamsChange={ (value)=> { setWithRealExams(value); setPage(1) ;}}
          onOwnQuestionsChange={ (value)=> { setOwnQuestions(value); setPage(1) ;}}


        />
        
       
      </SimpleGrid>
    </Box>
  );
}
