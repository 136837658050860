import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Text,
  Button,
  Flex,
  VStack,
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Badge,
  useDisclosure,
  useColorModeValue
} from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Comment from './components/Comment';
import RealExams from './components/RealExams';

import { createStandaloneToast } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon , ChevronUpIcon, ChevronDownIcon} from '@chakra-ui/icons';
import { MdCheck, MdFlag, MdCircle } from 'react-icons/md';
import {IconButton } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { Textarea } from '@chakra-ui/react'

import Helpers from '../../../helpers';
import { profile } from 'config/default_language';
import { set } from 'react-hook-form';

export default function TestQuick (props)  {

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBgnew = useColorModeValue("white", "whiteAlpha.100");


  let handle  = useParams();

  const toast = createStandaloneToast();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [questionTimes, setQuestionTimes] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [questionStartTime, setQuestionStartTime] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI Modal
  const [comments, setComments] = useState([]);
  const [test, setTest] = useState({});

  const autoNextQuestion = Helpers.getNextQuestionsSettings();

  const [questions, setQuestions] = useState([]);

  const [selectedFlags, setSelectedFlags] = useState({
    mark: false,
    review: false,
    green_flag: false,
    red_flag: false,
    yellow_flag: false,
  });

  const [globalselectedFlags, setGlobalSelectedFlags] = useState([]);

  const [flags, setFlags] = useState([]);

  useEffect(()=>{

    var existingFlags  = globalselectedFlags;
    
    existingFlags[currentQuestionIndex] = selectedFlags;

    setGlobalSelectedFlags(existingFlags);

  },[selectedFlags])

  const toggleFlagButton = async(name) => {

      if(selectedFlags[name] == true)
      {
        const flagId = flags.find(flag => flag.name === name)._id;
        await Helpers.deleteFlag(flagId);

      

      }
      if(selectedFlags[name] == false)
        {

        const NewFlag =  await Helpers.addFlag({name: name, questionId:questions[currentQuestionIndex]._id});
        setFlags([...flags, NewFlag]);

        }

    setSelectedFlags((prevState) => (
      {
      ...prevState,
      [name]: !prevState[name],
    }));




  
  };


  useEffect(()=>{

    if(handle.testid)
    {
      Helpers.getTest(handle.testid).then(async (response)=>{
        console.log("we got data", response);
    
            setTest(response.test);
            props.setCustomActiveRoute(response.test.name);
            setQuestions(response.questions);

          

            if(response?.test?.selectedAnswers)
            {
              setSelectedAnswers(response?.test?.selectedAnswers);

            }

            if(response?.test?.lastQuestionIndex)
            {
              setCurrentQuestionIndex(response?.test?.lastQuestionIndex);
            }
            

           
          });
    
    
       
    }
    
    
    
      }, [handle]);

  useEffect(() => {
     
      
        const mark = flags.filter(flag => flag.name === "mark" )?.length > 0;
        const review = flags.filter(flag => flag.name === "review" )?.length > 0;
        const green_flag = flags.filter(flag => flag.name === "green_flag" )?.length > 0;
        const red_flag = flags.filter(flag => flag.name === "red_flag" )?.length > 0;
        const yellow_flag = flags.filter(flag => flag.name === "yellow_flag" )?.length > 0;
        setSelectedFlags({
          mark: mark,
          review: review,
          green_flag: green_flag,
          red_flag: red_flag,
          yellow_flag: yellow_flag,
        })

      

   
  }, [flags]);




  useEffect(() => {
    setStartTime(new Date());
    setQuestionStartTime(new Date());

    //loadQuestionsTest();

   
  }, []);


  useEffect(() => {
    if (questions.length) {

      loadFlags();
      loadComments();
    }

  }, [questions]);



  useEffect(() => {
    if (currentQuestionIndex < questions.length && questionStartTime) {
      const now = new Date();
      setQuestionTimes((prevTimes) => {
        const newTimes = [...prevTimes];
        newTimes[currentQuestionIndex] = (now.getTime() - questionStartTime.getTime()) / 1000;
        return newTimes;
      });
      setQuestionStartTime(now);
      
    }
  
      loadFlags();
      loadComments();


    

  }, [currentQuestionIndex]);


    const loadQuestionsTest = async() =>
    {
      try {
        const questionsResponse = await  Helpers.getQuestions(30,"","","",null,null,'draft',null);
        console.log("questions response", questionsResponse);
  
        if(questionsResponse)
        {
          setQuestions(questionsResponse.questions);
        }

      

      }

      catch(error){
        console.log("error fetching questions", error);
      }
     
    }



    const loadFlags = async() =>
      {
        try {
          const flagsResponse = await  Helpers.getFlags(questions[currentQuestionIndex]._id);
          console.log("flags response", flagsResponse);


    
          if(flagsResponse)
          {
            setFlags(flagsResponse);
          }
  
        
  
        }
  
        catch(error){
          console.log("error fetching flags", error);
        }
       
      }


      const loadComments = async() =>
        {
          try {
            const commentsResponse = await  Helpers.getCommentsForQuestion(questions[currentQuestionIndex]._id);
            console.log("comments response", commentsResponse);
  
  
      
            if(commentsResponse)
            {
              setComments(commentsResponse);
            }
    
          
    
          }
    
          catch(error){
            console.log("error fetching comments", error);
          }
         
        }
    const onAddNewComment = async(text) =>
    {

      await Helpers.addComment({comment:text, questionId:questions[currentQuestionIndex]._id})

      const updatedComments = await Helpers.getCommentsForQuestion(questions[currentQuestionIndex]._id);

      if(updatedComments)
      {
        setComments(updatedComments);
      }

      toast({
        title: 'Success!',
        description: "Comment has been addess succesfully!.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    }
  const handleAnswer = async (optionId, correctAnswer) => {
    setSelectedAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentQuestionIndex] = optionId;
      return newAnswers;
    });
    if(optionId ==correctAnswer)
    {
      await Helpers.updateTest(handle.testid,{questionIndex:currentQuestionIndex, questionId:questions[currentQuestionIndex]._id, answerResult:'correct', selectedAnswer:optionId, correctAnswer:questions[currentQuestionIndex].correctAnswer});

      toast({
        title: 'Correct!',
        status: 'success',
        duration: 1000,
        isClosable: true,
      })
      
    }
    else if(optionId != correctAnswer)
      {

        await Helpers.updateTest(handle.testid,{questionIndex:currentQuestionIndex, questionId:questions[currentQuestionIndex]._id, answerResult:'incorrect', selectedAnswer:optionId, correctAnswer:questions[currentQuestionIndex].correctAnswer});
  
        toast({
          title: 'False',
          status: 'error',
          duration: 1000,
          isClosable: true,
        })
        
      }

    if (currentQuestionIndex === questions.length - 1) {
      if (startTime) {
        const now = new Date();
        const totalTime = (now.getTime() - startTime.getTime()) / 1000;
        //onComplete(totalTime, questionTimes);
      }
    } else {
      setTimeout(() => {
        if(autoNextQuestion)
        {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
   
      }, 1000);
    }
  };

  const renderQuestionImages = () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion.images) return null;

    return (
      <Box display="flex" flexDirection="row" overflowX="auto" mb={4}>
       
        {currentQuestion.images.map((imageUri, index) => (
          <Image
            onClick={()=>        window.open('/viewer.html?image='+imageUri, '_blank')            }
            key={index}
            src={Helpers.getImageURL(imageUri)}
            boxSize="100px"
            borderRadius="10px"
            objectFit="cover"
            mr={3}
          />
        ))}
      </Box>
    );
  };

  const renderQuestion = () => {
    const currentQuestion = questions.length &&  questions[currentQuestionIndex];
    const selectedAnswer = selectedAnswers[currentQuestionIndex];
    const isAnswered = selectedAnswer !== null;

    return (
      <Box mt={5}   >

        <Text fontSize="lg" fontWeight="bold" mb={4}  >
        
          <div dangerouslySetInnerHTML={{ __html: currentQuestion.text }} />
        </Text>
        {renderQuestionImages()}
        <VStack spacing={4}>
          {currentQuestion.options.map((option, index) => (



            <Button
              key={index}
              w="100%"
              variant={
                (selectedAnswer == option.id && option.id === currentQuestion.correctAnswer )|| (selectedAnswer != option.id && selectedAnswer!=null) && option.id === currentQuestion.correctAnswer
                 ? 'solid'
                 : selectedAnswer == option.id && option.id !== currentQuestion.correctAnswer
                 ? 'solid'
                 : 'outline'
             }
              size="lg"
              borderRadius={3}
              _hover={selectedAnswer== null && {transform:'scale(1.01, 1.01)', backgroundColor:'gray.200', opacity:1 }}
              
              style={{padding:20, fontSize:20, justifyContent:"left"}}
              colorScheme={
                 (selectedAnswer == option.id && option.id === currentQuestion.correctAnswer )|| (selectedAnswer != option.id && selectedAnswer!=null) && option.id === currentQuestion.correctAnswer
                  ? 'green'
                  : selectedAnswer == option.id && option.id !== currentQuestion.correctAnswer
                  ? 'red'
                  : ''
              }
              isDisabled={selectedAnswer!=null}
              _disabled={{opacity:1}}
              onClick={() => handleAnswer(option.id, currentQuestion.correctAnswer)}
            >
              <Flex style={{flex:1, justifyContent:'space-between', alignItems:'center'}}>
              <Flex style={{textTransform:'uppercase'}}>
              {option.id}

                </Flex>
        
              <Flex style={{justifyContent:'flex-start',width:'80%',textWrap:'wrap'}}>
              <div dangerouslySetInnerHTML={{ __html: option.text }} />

              </Flex>

              </Flex>
           

            
                          </Button>

          ))}
        </VStack>
      </Box>
    );
  };

  const renderExplanationModal = () => {
    const currentQuestion = questions[currentQuestionIndex];
    return (
          <>
           <Text mb={4}>          <div dangerouslySetInnerHTML={{ __html: currentQuestion.explanation.text }} />
            </Text>
            {currentQuestion.explanation.images &&
              currentQuestion.explanation.images.map((imageUri, index) => (
                <Image
                  onClick={()=>        window.open('/viewer.html?image='+imageUri, '_blank')            }

                  key={index}
                  src={Helpers.getImageURL(imageUri)}
                  boxSize="200px"
                  objectFit="cover"
                  borderRadius="10px"
                  mb={3}
                />
              ))}
          </>

           
      
    );
  };


  const SidebarCarousel = () => {
    const goToPrevious = () => {
      setCurrentQuestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    };
  
    const goToNext = () => {
      setCurrentQuestionIndex((prevIndex) =>
        prevIndex < questions.length - 1 ? prevIndex + 1 : prevIndex
      );
    };
  
    return (
      <HStack
        justify="center"
        mb={4}
        spacing={1}
        bg={boxBgnew} 
        textColor={textColor}
        padding={4}
        position={'sticky'}
        zIndex={999}
        borderRadius="lg"
        borderColor="gray.200"
        borderWidth={1}
        maxWidth="100%"
        overflow="hidden"
      >
        {/* Scrollable Vertical Container */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)" // Display 4 items per line
          maxHeight="100%" // Set max height for the scrollable area
          padding="5px"
          gap={2} // Gap between items
          css={{
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar in WebKit-based browsers (Chrome, Safari)
            },
            '-ms-overflow-style': 'none',  // Hide scrollbar in Internet Explorer and Edge
            'scrollbar-width': 'none',     // Hide scrollbar in Firefox
          }}
        >
          {questions.map((question, index) => (
            <Box
              key={index}

              position="relative"
              w={10} // Adjust width
              h={10} // Adjust height
              borderRadius="md"
              bg={
                index === currentQuestionIndex
                  ? "yellow.300"
                  : selectedAnswers[index] === null || selectedAnswers[index] === undefined
                  ? "gray.300"
                  : selectedAnswers[index] === questions[index].correctAnswer
                  ? "green.400"
                  : "red.400"
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={() => setCurrentQuestionIndex(index)}
            >
              {globalselectedFlags[index]?.review && <MdFlag style={{position:'absolute', right:-5, top:-5}} />}
              {globalselectedFlags[index]?.mark && <MdCheck style={{position:'absolute', right:-5, top:-5}} />}
              {globalselectedFlags[index]?.green_flag && <MdCircle color="green" style={{position:'absolute', right:-5, top:-5}} />}
              {globalselectedFlags[index]?.red_flag && <MdCircle color="red" style={{position:'absolute', right:-5, top:-5}} />}
              {globalselectedFlags[index]?.yellow_flag && <MdCircle color="yellow" style={{position:'absolute', right:-5, top:-5}} />}

              

              <Text fontWeight="bold" color="white">
                {index + 1}
              </Text>
            </Box>
          ))}
        </Box>
      </HStack>
    );
  };
  

  const renderCarousel = () => {
    const goToPrevious = () => {
      setCurrentQuestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    };
  
    const goToNext = () => {
      setCurrentQuestionIndex((prevIndex) =>
        prevIndex < questions.length - 1 ? prevIndex + 1 : prevIndex
      );
    };
  
    return (
      <HStack
        justify="center"
        mb={8}
        spacing={4}
        bg={boxBgnew} 
        textColor={textColor}
        padding={5}
        position={'-webkit-sticky'}
        zIndex={999999999999}
        borderRadius="lg"
        borderColor="gray.200"
        borderWidth={1}
        maxWidth="100%" // Make sure the container can grow up to 100%
      >
        {/* Left Arrow */}
        <Box
            bg={boxBgnew} 
            textColor={textColor}
          as="button"
          onClick={goToPrevious}
          disabled={currentQuestionIndex === 0}
          opacity={currentQuestionIndex === 0 ? 0.5 : 1}
          cursor={currentQuestionIndex === 0 ? "not-allowed" : "pointer"}
        >
          <ChevronLeftIcon w={8} h={8} color="gray.600" />
        </Box>
  
        {/* Scrollable Question Boxes with Hidden Scrollbar */}
        <Box
          display="flex"
          overflowX="auto" // Enable horizontal scrolling
          maxWidth="80%" // Limit width for question boxes
          padding="0 10px" // Add padding for better spacing
          css={{
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar in WebKit-based browsers (Chrome, Safari)
            },
            '-ms-overflow-style': 'none',  // Hide scrollbar in Internet Explorer and Edge
            'scrollbar-width': 'none',     // Hide scrollbar in Firefox
          }}
        >
          {questions.map((question, index) => (
            <Box
              key={index}
              w={10}
              h={10}
              minWidth={10}
              borderRadius="full"
              bg={
                index === currentQuestionIndex
                  ? "yellow.300"
                  : (selectedAnswers[index] === null || selectedAnswers[index] === undefined    ) // Explicit null check
                  ? "gray.300" // Apply gray if the answer is null
                  : selectedAnswers[index] === questions[index].correctAnswer
                  ? "green.400"
                  : "red.400"
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={() => setCurrentQuestionIndex(index)}
              marginX={2} // Space between the circles
            >
              <Text fontWeight="bold" color="white">
                {index + 1}
              </Text>
            </Box>
          ))}
        </Box>
  
        {/* Right Arrow */}
        <Box
          as="button"
          onClick={goToNext}
          disabled={currentQuestionIndex === questions.length - 1}
          opacity={currentQuestionIndex === questions.length - 1 ? 0.5 : 1}
          cursor={
            currentQuestionIndex === questions.length - 1
              ? "not-allowed"
              : "pointer"
          }
        >
          <ChevronRightIcon w={8} h={8} color="gray.600" />
        </Box>
      </HStack>
    );
  };
  
  

  return (
    <Box p={8}  minH="100vh" style={{marginTop:50}}  >


      {questions.length && renderCarousel()}
      
      <Flex>
      
      <Box mr={2} borderWidth='1px' p={4} borderRadius='lg' width={'100%'}    bg={boxBgnew} 
        textColor={textColor} style={{position:'relative'}}>


   
      <Badge style={{position:'absolute', top:-10}} colorScheme="green">#{questions.length && questions[currentQuestionIndex].internal_id}</Badge>

      <Tabs colorScheme='teal'> 
    <TabList >
    <Tab>Question</Tab>
    <Tab>Explanation</Tab>
    <Tab>Notes</Tab>
    <Tab style={{position:'relative'}}>Exam Questions {questions[currentQuestionIndex]?.realExams?.length && <Badge style={{position:'absolute', right:0, top:-10, borderRadius:99}} colorScheme='teal'>{questions[currentQuestionIndex]?.realExams?.length}</Badge>}</Tab>
    <Tab style={{position:'relative'}}>Comments {comments?.length && <Badge style={{position:'absolute', right:0, top:-10, borderRadius:99}} colorScheme='teal'>{comments?.length}</Badge>}</Tab>
  
    <HStack spacing={4} style={{justifyContent:'flex-end', flex:1, padding:4}}>
      {/* Mark Icon Button */}
      <IconButton
        aria-label="Mark"
        icon={<MdCheck />}
        onClick={() => toggleFlagButton('mark')}
        variant={selectedFlags.mark ? 'solid' : 'outline'}
        colorScheme={selectedFlags.mark ? 'blue' : 'gray'}
      />

      {/* Flag Icon Button */}
      <IconButton
        aria-label="Flag"
        icon={<MdFlag />}
        onClick={() => toggleFlagButton('review')}
        variant={selectedFlags.review ? 'solid' : 'outline'}
        colorScheme={selectedFlags.review ? 'red' : 'gray'}
      />

      {/* Green Circle Button */}
      <IconButton
        aria-label="Green Circle"
        icon={<MdCircle />}
        onClick={() => toggleFlagButton('green_flag')}
        variant={selectedFlags.green_flag ? 'solid' : 'outline'}
        colorScheme="green"
      />

      {/* Red Circle Button */}
      <IconButton
        aria-label="Red Circle"
        icon={<MdCircle />}
        onClick={() => toggleFlagButton('red_flag')}
        variant={selectedFlags.red_flag ? 'solid' : 'outline'}
        colorScheme="red"
      />

      {/* Yellow Circle Button */}
      <IconButton
        aria-label="Yellow Circle"
        icon={<MdCircle />}
        onClick={() => toggleFlagButton('yellow_flag')}
        variant={selectedFlags.yellow_flag ? 'solid' : 'outline'}
        colorScheme="yellow"
      />
    </HStack>
  
  </TabList>

  <TabPanels>
    <TabPanel>
    {questions.length && renderQuestion()}
    </TabPanel>
    <TabPanel    bg={boxBgnew} 
        textColor={textColor}>
    {questions.length && renderExplanationModal()}
    </TabPanel>
    <TabPanel>
    <Textarea placeholder='Your Notes'    bg={boxBgnew} 
        textColor={textColor} />
    </TabPanel>
    <TabPanel>
    {questions.length && <RealExams user={props?.profileData?.username} realExams={questions[currentQuestionIndex].realExams} onNewComment={onAddNewComment}/>}
    </TabPanel>
    <TabPanel>
    <Comment user={props?.profileData?.username} comments={comments} onNewComment={onAddNewComment}/>
    </TabPanel>
  </TabPanels>
</Tabs>
</Box>



{questions.length && SidebarCarousel()}
</Flex>
      

    
    </Box>
  );
};

