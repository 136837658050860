'use client'; // Ensure this is used if you're working with Next.js and React 18+.

import React from 'react';
import {
  Box,
  Container,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

const faqData = [
  {
    question: "What is the purpose of this platform?",
    answer: "Our platform helps you prepare for pilot exams by providing practice questions, detailed explanations, and other resources to improve your chances of passing the PPL and ATPL exams.",
  },
  {
    question: "How can I start a free trial?",
    answer: "To start a free trial, simply click on the 'Start Free Trial' button on our homepage. You'll need to provide basic information such as your name and email address.",
  },
  {
    question: "What types of questions are included in the practice tests?",
    answer: "The practice tests include real exam questions from past papers, covering a wide range of topics relevant to your pilot certification exams.",
  },
  {
    question: "Can I access the platform from any device?",
    answer: "Yes, our platform is accessible from any device with an internet connection, including desktops, tablets, and smartphones.",
  },
  {
    question: "What support do you offer if I have questions?",
    answer: "We offer support through our forum where you can interact with trainers and other users. Additionally, you can contact our support team via email for any specific queries.",
  },
  {
    question: "How often are the practice questions updated?",
    answer: "We regularly update our practice questions to ensure they reflect the most recent exam formats and include new content as it becomes available.",
  },
];

const FAQ = () => {
  // Use useColorModeValue to set values based on the color mode
  const bg = useColorModeValue('white', 'gray.800');
  const boxShadow = useColorModeValue('md', 'dark-lg');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const headingColor = useColorModeValue('gray.800', 'white');
  const questionTextColor = useColorModeValue('gray.800', 'gray.100');
  const answerTextColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Box bg={bg} boxShadow={boxShadow} borderRadius="md" py={{ base: 10, md: 20 }}>
      <Container maxW="2xl">
        <Stack spacing={8} textAlign="center">
          <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
            Frequently Asked Questions
          </Heading>
          <Accordion allowMultiple>
            {faqData.map((item, index) => (
              <AccordionItem
                key={index}
                borderWidth="1px"
                borderRadius="md"
                borderColor={borderColor}
                mb={4}
              >
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontWeight="bold" color={questionTextColor}>
                        {item.question}
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text color={answerTextColor}>{item.answer}</Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Stack>
      </Container>
    </Box>
  );
};

export default FAQ;
