import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Text,
  VStack,
  Heading,
  Badge,
  SimpleGrid,
  Stack,
  Flex,
  List,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";

const PricingTab = ({ plans }) => {
  const [planType, setPlanType] = useState("PPL");

  const handlePurchase = async (plan) => {
    // Navigate to the sign-up page
    window.location.href = "/#/auth/sign-up";
  };

  const pricing = {
    PPL: {
      monthly: { price: "$19/month", features: ["Feature A", "Feature B", "Feature C"] },
      quarterly: { price: "$49/quarter", features: ["Feature A", "Feature B", "Feature C"] },
      yearly: { price: "$179/year", features: ["Feature A", "Feature B", "Feature C"] },
    },
    ATPL: {
      monthly: { price: "$29/month", features: ["Feature D", "Feature E", "Feature F"] },
      quarterly: { price: "$79/quarter", features: ["Feature D", "Feature E", "Feature F"] },
      yearly: { price: "$299/year", features: ["Feature D", "Feature E", "Feature F"] },
    },
    "All in One": {
      monthly: { price: "$49/month", features: ["All Features from PPL and ATPL", "Priority Support", "Unlimited Access"] },
      quarterly: { price: "$129/quarter", features: ["All Features from PPL and ATPL", "Priority Support", "Unlimited Access"] },
      yearly: { price: "$499/year", features: ["All Features from PPL and ATPL", "Priority Support", "Unlimited Access"] },
    },
  };

  // Define color values using useColorModeValue outside of the JSX
  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const headingColor = useColorModeValue('gray.800', 'white');
  const buttonColorScheme = useColorModeValue('blue', 'teal');

  return (
    <Box p={8} bg={bg} borderRadius="md" borderColor={borderColor} boxShadow="lg">
      <Heading mb={8} textAlign="center" color={headingColor}>
        Available Plans
      </Heading>
      <Stack direction={{ base: "column", md: "row" }} spacing={8} justifyContent={"center"}>
        {plans.map((plan) => (
          <Flex
            key={plan._id}
            borderWidth="1px"
            borderRadius="lg"
            p={6}
            bg={bg}
            boxShadow="lg"
            w={{ base: "full", md: "300px" }}
            direction="column"
            borderColor={borderColor}
            _hover={{
              transform: "scale(1.05)",
              transition: "all 0.3s ease-in-out",
            }}
          >
            <VStack spacing={4} align="start" flexGrow={1}>
              <Heading size="md" color={textColor}>
                {plan.name}
              </Heading>

              <Text color={textColor}>
                Price: ${plan.price} / {plan.interval}
              </Text>

              {plan.sale_price && (
                <Text color="green.500">
                  Sale Price: ${plan.sale_price}
                </Text>
              )}

              {plan.trial && (
                <Badge colorScheme="green">
                  {plan.trialDays} Days Free Trial
                </Badge>
              )}

              <List spacing={2} color={textColor}>
                {plan.features.map((feature, index) => (
                  <ListItem key={index}>✔ {feature}</ListItem>
                ))}
              </List>
            </VStack>

            {/* Keep the button always at the bottom */}
            <Button
              onClick={() => handlePurchase(plan)}
              colorScheme={buttonColorScheme}
              width="full"
              mt="auto" // Pushes the button to the bottom of the card
              target="_blank"
            >
              Purchase Plan
            </Button>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default PricingTab;
