'use client'; // Ensure this is used if you're working with Next.js and React 18+.

import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Stack,
  useDisclosure,
  Box,
  Heading,
  Image,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';

const avatars = [ 'avatar-main.png'
  
];

const SignUpModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const { control, handleSubmit, getValues, setValue } = useForm();
  const [selectedAvatar, setSelectedAvatar] = useState('');

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    setStep(step - 1);
  };

  const onSubmit = (data) => {
    console.log('Form Data:', data);
    onClose(); // Close modal on successful submission
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sign Up</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {step === 1 && (
              <>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Input type="email" {...field} />
                    )}
                    rules={{ required: 'Email is required' }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input type="password" {...field} />
                    )}
                    rules={{ required: 'Password is required' }}
                  />
                </FormControl>
              </>
            )}

            {step === 2 && (
              <>
                <FormControl>
                  <FormLabel>Full Name</FormLabel>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <Input type="text" {...field} />
                    )}
                    rules={{ required: 'Full Name is required' }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <Textarea {...field} />
                    )}
                    rules={{ required: 'Address is required' }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Country</FormLabel>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <option value="USA">USA</option>
                        <option value="Canada">Canada</option>
                        <option value="UK">UK</option>
                        {/* Add more options as needed */}
                      </Select>
                    )}
                    rules={{ required: 'Country is required' }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <Input type="tel" {...field} />
                    )}
                    rules={{ required: 'Phone Number is required' }}
                  />
                </FormControl>
              </>
            )}

            {step === 3 && (
              <>
                <FormLabel>Select Avatar</FormLabel>
                <Box display="flex" flexWrap="wrap" gap={4}>
                  {avatars.map((avatar, index) => (
                    <Box
                      key={index}
                      borderWidth={selectedAvatar === avatar ? '2px' : '1px'}
                      borderColor="teal.500"
                      borderRadius="md"
                      p={2}
                      onClick={() => setSelectedAvatar(avatar)}
                      cursor="pointer"
                      _hover={{ shadow: 'md' }}
                    >
                      <Image src={avatar} boxSize="100px" objectFit="cover" />
                    </Box>
                  ))}
                </Box>
                <FormControl mt={4}>
                <Controller
                    name="avatar"
                    control={control}
                    render={({ field }) => (
                        <Input type="hidden" name={"avatar"}  value={selectedAvatar}/>
                    )}
                  
                  />
                 
                </FormControl>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          {step > 1 && (
            <Button variant="outline" mr={3} onClick={onBack}>
              Back
            </Button>
          )}
          {step < 3 ? (
            <Button colorScheme="teal" onClick={onNext}>
              Next
            </Button>
          ) : (
            <Button
              colorScheme="teal"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignUpModal;
