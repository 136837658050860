
import default_language from "config/default_language";
export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },  
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
export const newUsersColumns = [
  {
    Header: default_language.email,
    accessor: "email",
  },
  {
    Header: default_language.date,
    accessor: "registrationDate",
  },
  {
    Header: default_language.country,
    accessor: "country",
  },
  
  {
    Header: default_language.actions,
    accessor: "actions",
  }
];