/* eslint-disable */
import {
    Box,
    Flex,
    Progress,
    Table,
    Tbody,
    Td,
    Button,
    Text,
    Th,
    Thead,
    Tr,Center,
    useColorModeValue,
    InputGroup,
    Input,
    InputLeftElement,
    IconButton,
    SimpleGrid,
    ButtonGroup,
    Select,
    Option,
    Badge,
 Checkbox, 
 CheckboxGroup ,

  } from "@chakra-ui/react";
  import {EmailIcon,  SearchIcon, DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
useHistory
  // Custom components
  import { useEffect, useState } from 'react';
  import "react-datepicker/dist/react-datepicker.css";

  import Card from "components/card/Card";
  import Menu from "components/menu/MainMenu";
  import React, { useMemo } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
import default_language from "config/default_language";
  import {NavLink, useHistory} from 'react-router-dom';
  import Helpers from "helpers";
  import DatePicker from "react-datepicker";

  export default function DevelopmentTable(props) {
    const { columnsData, tableData } = props;
    const history = useHistory();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]


    const [startDate, setStartDate] = useState(formattedDate);

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

   const Pagination = () =>{
        var pagesExport = [];

        for (let i = 1; i <= props.totalPages; i++) {
            pagesExport.push( <Button onClick={()=>props.onPageChange(i)}  variant={i == props.currentPage ? 'brand' : 'outline' } aria-label='Delete' icon={<DeleteIcon /> } >{i}</Button>
            )
        }



        return (pagesExport);
    }


  
    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
    } = tableInstance;
    initialState.pageSize = 11;
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("secondaryGray.500", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
                  <Flex px='25px' justify='space-between' mb='20px' align='center'>
                  <SimpleGrid columns={{ base: 2, md: 2, lg: 2 }} spacing={10}>

             <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'>
            {props.title}
          </Text>
          <span>Found {props.totalLogs} logs</span>

          <InputGroup style={{width:'100%'}}>
    <InputLeftElement pointerEvents='none'>
      <SearchIcon color='gray.300' />
    </InputLeftElement>
    <Input type='text' placeholder={default_language.search_dots} onChange={(event)=>props.onSearch(event.target.value)} value={props.searchValue} />
  </InputGroup>


  <InputGroup style={{width:'100%'}}>
  <DatePicker value={props.date} selected={startDate} dateFormat="Pp"  onChange={(date) => {
    // Convert date to "YYYY-mm-dd" format
    const formattedDate = date.toISOString().slice(0, 10);
    props.onChangeDate(formattedDate);
  }} />

<DatePicker value={props.dateTo} selected={startDate} dateFormat="Pp"  onChange={(date) => {
    // Convert date to "YYYY-mm-dd" format
    const formattedDate = date.toISOString().slice(0, 10);
    props.onChangeDateTo(formattedDate);
  }} />
 
    <Input style={{display:'None'}} type='text' placeholder={"Date"} onChange={(event)=>props.onChangeDate(event.target.value)} value={props.date} />
  </InputGroup>

  


</SimpleGrid>
          </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
     
        <SimpleGrid columns={{ base: 2, md: 5, lg: 5 }} spacing={10}>


         
        
        
         








<Select placeholder={default_language.all} onChange={(event)=> props.onChangeUser(event.target.value)} value={props.user}>

{ props?.users && 
      props?.users.map((user)=>{

        return (  <option value={user._id}>{user.email}</option>
        )
      })
    }
</Select>
<Select placeholder={default_language.all} onChange={(event)=> props.onChangeAction(event.target.value)} value={props.action}>

<option value="QuestionAdd">QuestionAdd</option>
<option value="QuestionEdit">QuestionEdit</option>
<option value="AnswerEdit">AnswerEdit</option>
<option value="ExplanationEdit">ExplanationEdit</option>
<option value="ImagesEdit">ImagesEdit</option>
<option value="DatabaseEdit">DatabaseEdit</option>
<option value="SubjectEdit">SubjectEdit</option>

</Select>

<Select placeholder={default_language.study} onChange={(event)=> props.onChangeStudy(event.target.value)} value={props.study}>

{ props?.studies && 
  props?.studies.map((study)=>{
    return (  <option value={study._id}>{study.title}</option>
    )
  })
}

</Select>


<Select placeholder={default_language.all} onChange={(event)=> props.onChangeSubject(event.target.value)} value={props.subject}>

{ props?.subjects && 
  props?.subjects.map((subject)=>{

    return (  <option value={subject._id}>{subject.title}</option>
    )
  })
}
</Select>

  </SimpleGrid>
          <Menu />
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index} >
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              console.log("here is the row");
              console.log(row);
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index} onClick={()=>history.push('/admin/question/'+row.original?.question?._id)}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.id === "text") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                          <Badge ml="1" colorScheme="green" style={!Helpers.dateWithinLast15Days(cell.row.original.registrationDate ) ? {display:'none'}:{}}>
        New
      </Badge>
                        </Text>
                        
                      );
                    }
                
                      if (cell.column.id === "action") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        );
                      }
                      if (cell.column.id === "timestamp") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {Helpers.formatTimestamp(cell.value)}
                          </Text>
                        );
                      } 
                      if (cell.column.id === "question") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.row.original?.question?.text.replace(/<[^>]*>?/gm, '')}
                          </Text>
                        );
                      } 
                      if (cell.column.id === "id") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.row.original?.question?.internal_id}
                          </Text>
                        );
                      } 
                      if (cell.column.id === "database") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.row.original?.study?.title.replace(/<[^>]*>?/gm, '')}
                          </Text>
                        );
                      }
                      if (cell.column.id === "subject") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.row.original?.subject?.title.replace(/<[^>]*>?/gm, '')}
                          </Text>
                        );
                      } 

                      if (cell.column.id === "description") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.row.original?.description}
                          </Text>
                        );
                      } 
            
                  
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        
          
        
        </Table>
        <Center>
        <Flex alignContent={'center'} > 
        <SimpleGrid wrap={'auto'} columns={'20'} spacing={1}>
                    <Pagination/>

    

        </SimpleGrid>
                


          </Flex>
          </Center>
      </Card>
    );
  }
  