import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Badge,
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  Icon,
  HStack,
  Select,
  Text,
  Stack,
  createStandaloneToast
} from "@chakra-ui/react";

import {
MdArrowForward,MdArrowBack,
  } from "react-icons/md";
import Helpers from '../../../../helpers';
// Message component to handle the rendering and POST request
const MessagesComponent = ({ messages, profileData, totalMessages, totalPages, currentPage, setPage }) => {

        const toast = createStandaloneToast();

    const [newMessage, setNewMessage] = useState(""); // State for new message
    const [sending, setSending] = useState(false); // Loading state for sending message
  // Function to mark message as seen
  const markAsSeen = async (messageId) => {
    try {
      const seenResponse =   await Helpers.markMessageAsSeen(messageId);
      console.log(seenResponse);
    } catch (error) {
        console.log(error);

    }
  };

   // Function to send new message
   const sendMessage = async () => {
    if (newMessage.trim() === "") return; // Don't send empty message

    setSending(true); // Set loading state
    try {
        await Helpers.sendMessage(newMessage);
      console.log("Message sent:");
      setNewMessage(""); // Clear the message input
    } catch (error) {
      console.error("Failed to send message", error);
    } finally {
        toast({
            title: 'Message Sent.',
            description: "We will back to your shortly!",
            status: 'success',
            duration: 2000,
            isClosable: true,
          })
      setSending(false); // Reset loading state
    }
  };

  return (
    <Box>

 <Box as="form" mb={4} bg="white" p={4} borderRadius={10}>
        <FormControl isRequired>
          <FormLabel>New Message</FormLabel>
          <Textarea
            placeholder="Type your message here..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            rows={3}
          />
        </FormControl>
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={sending}
          onClick={sendMessage}
          disabled={sending || newMessage.trim() === ""}
        >
          Send Message
        </Button>
      </Box>

      <Flex justify="space-between" align="center" p={4} bg="gray.100" borderRadius="md">
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Total Messages: {totalMessages}
        </Text>
      </Box>
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Total Pages: {totalPages}
        </Text>
      </Box>
    </Flex>
    


    <Accordion allowToggle>
      {messages.map((message) => {
        const isSentByMe = message.user._id === profileData._id;
        const isSentToMe =(message.toUser === profileData._id) || (message.toUser === 'moderator'); // Assuming "moderator" is the current user role

        return (
          <AccordionItem key={message._id} bg={!message.seen && isSentToMe ? "green.100" : "white"}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => {
                    if (!message.seen &&  !isSentByMe) {
                      markAsSeen(message._id);
                    }
                  }}
                >
                  <Box flex="1" textAlign="left" >
                    <HStack style={{alignItems:"center",justifyContent:"space-between", alignContent:'flex-start', flex:'1'}}>
                    {isSentByMe  && <Icon as={MdArrowBack} width='20px' height='20px' color='inherit' /> }
                    
                    {isSentToMe && !isSentByMe && <Icon as={MdArrowForward} width='20px' height='20px' color='inherit' /> }
                    

                        <Badge colorScheme="teal" style={{alignSelf:'flex-start'}}>
                        {new Date(message.datetime).toLocaleString()}
                        </Badge>

                        <Text  fontWeight={!message.seen && isSentToMe ? "bold" : "normal"} style={{textAlign:'left'}}>
                      {message.message.slice(0, 20)}...
                    </Text>
                    <Stack style={{alignItems:"flex-end"}}>


                    
                     {isSentToMe && !isSentByMe && <Badge colorScheme={isSentToMe && !isSentByMe ? 'blue':'teal'}>
                        {isSentToMe && !isSentByMe && "By "+message.user.firstName}

                    </Badge>}


                    {isSentByMe && <Badge colorScheme={isSentByMe ? 'blue':'teal'}>
                    {isSentByMe && 'To '+message.user.firstName}

                    </Badge>}




                    </Stack>
                  
                    </HStack>
                   
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>
                  <Stack spacing={2}>
                    <Text>
                      <strong>
                        {isSentByMe ? "Sent from me" : `Sent by ${message.user.firstName} to ${(message.toUser== 'moderator')? 'Moderators Team': 'to Me'}`}
                      </strong>
                    </Text>
                    <Box bg="gray.100" p={4} borderColor="gray.300" borderWidth={1} borderRadius={10}>
                    <Text>{message.message}</Text>

                    </Box>
                  
                  </Stack>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>


    <HStack spacing={2} mt={4}>
      {Array.from({ length: totalPages }, (_, index) => (
        <Button
          key={index}
          onClick={() => setPage(index + 1)}
          colorScheme={currentPage === index + 1 ? 'teal' : 'gray'}
        >
          {index + 1}
        </Button>
      ))}
    </HStack>

    </Box>


  
  );
};

export default MessagesComponent;
