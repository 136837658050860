// Chakra imports
import {
    Box,
    Flex,
    Text,
    HStack,
    Badge,
    Button,
    VStack,
    Tag,
    Icon,
    useColorModeValue,
    Checkbox,
    IconButton,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import Menu from "components/menu/MainMenu";
  import IconBox from "components/icons/IconBox";
  import {NavLink, useHistory} from 'react-router-dom';

  // Assets
  import { MdCheckBox, MdDragIndicator, MdBook, MdNewLabel, MdDelete } from "react-icons/md";
  import React from "react";
  import Helpers from '../../../../helpers';
  export default function Conversion(props) {
    const { ...rest } = props;
    const history = useHistory();

    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
    const brandColor = useColorModeValue("brand.500", "brand.400");
    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}>
        <Flex alignItems='center' w='100%' mb='30px'>
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            icon={<Icon as={MdBook} color={brandColor} w='24px' h='24px' />}
          />
  
          <Text color={textColor} fontSize='xl' fontWeight='700'>
            My Tests
          </Text>
        { /* <Menu ms='auto' /> */}
        </Flex>
        <Box px='11px'>
         
            { props?.tests.length > 0 &&props?.tests.map((test)=>{
                return (<Flex flexWrap={"wrap"} justifyContent={'space-between'}  justifyContent={"space-between"} mb='20px' _hover={{cursor:'pointer', opacity:0.6}} style={{borderBottomWidth:1, padding:10}} > 
                   <HStack justifyContent={"space-around"} width='100%'>

                   <Text 
                      fontWeight='bold'
                      color={textColor}
                      fontSize='md'  
                      onClick={()=>history.push('/user/test/'+test._id) }
                      marginRight={10}
                      textAlign='start'>
                        
                      {test.name}
                     
                    </Text>
                    <VStack   onClick={()=>history.push('/user/test/'+test._id) } alignItems={'left'} textAlign={"left"} justifyContent={'left'} flexWrap={'wrap'} width="100%">

<HStack justifyContent={'left'} flexWrap={'wrap'} gap={3}>



{test.subjects.map((subject)=>{
    return      <Badge colorScheme="teal">
        {subject.title}
    </Badge>

})}
  </HStack>

  <HStack justifyContent={'left'}>

    {test.successRate && <Badge colorScheme={test.successRate > 80 ? 'green': 'red'}>
        {test.successRate+"%"} Success
    </Badge>}

   {test?.answeredQuestions && <Badge>
        Answered: {test?.answeredQuestions+"/"+test?.totalQuestions}
    </Badge>}
    {!test?.answeredQuestions && <Badge colorScheme="red">
        Test not Started
    </Badge>}

    {test?.wrongQuestions?.length && <Badge>
        Wrong Questions: {test?.wrongQuestions?.length}
    </Badge>}


<Text  textAlign='end' >
{Helpers.formatTimestamp(test.datetime)}
</Text>
</HStack>
</VStack>

<IconButton justifyContent={"center"} icon={<MdDelete/>} _hover={{backgroundColor:'red'}} onClick={()=>props?.deleteTest(test?._id)}></IconButton>
                   </HStack>
                 
                   
                    </Flex>);





            })}



            { (props?.tests?.length == 0 )|| (!props?.tests?.length)  && <Box>

              <Button colorScheme="brand" onClick={()=>history.push('/user/newtest')} leftIcon={ <Icon as={MdNewLabel} color={'white'} w='24px' h='24px' />}>
              Create your first test!

              </Button>
              
              </Box>}
         


         
        </Box>
      </Card>
    );
  }
  