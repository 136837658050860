// Chakra imports
import { Portal, Box, useDisclosure, Text, Button, Link } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import routes from 'routes.js';
import Helpers from '../../helpers';
import Test from "../../views/user/test";
import Profile from "../../views/user/profile";
import default_language from "config/default_language";

// Custom Chakra theme
export default function Dashboard(props) {
	const { ...rest } = props;
	// states and functions
	const [ fixed ] = useState(false);
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	// functions for changing the states from components
	const [profileData, setProfileData] = useState(null);
	const [activeRoutes, setActiveRoutes] = useState([]);
	const [customActiveRouteName, setCustomActiveRouteName] = useState("");


	const setCustomActiveRoute = async(name) =>{
		setCustomActiveRouteName(name);
	}

	const reFetchProfileData = async() =>{
		Helpers.getProfileData().then((response=>
			{
				console.log("got profile data");
				setProfileData(response);
				console.log(response);
			}));
	}

	useEffect(()=>{

	

	
		Helpers.getProfileData().then((response=>
			{
				console.log("got profile data");
				setProfileData(response);
				console.log(response);
			}));

		

			


	}, [])


	useEffect(()=>{

		
		if(profileData?.role == "admin" || profileData?.role == "moderator" )
		{
			var updatedRoutes = [];
			routes.map((prop, key)=>{

				
				
				if(prop.layout == '/admin' && !prop.exclude_from_menu)
				{
					if(prop.exclude_for_moderators && profileData?.role == "moderator" )
				{

				}
				else
				{
					updatedRoutes.push(prop);

				}
				}

				setActiveRoutes(updatedRoutes);

			})
		}




		if(profileData?.role == "student")
		{
			var updatedRoutes = [];
			routes.map((prop, key)=>{
				if(prop.layout == '/user')
				{
					updatedRoutes.push(prop);
				}
				setActiveRoutes(updatedRoutes);

			})
		}

			


	}, [profileData])


	useEffect(()=>{

		
		setCustomActiveRoute(getActiveRoute(routes));


		

	},[window.location.href])


	

	const getRoute = () => {

		const expired =   Helpers.isTokenExpired();
		console.log(expired);
		
	
		if(expired)
		{
		  return false;
		  
		  
	
	
		}
		else{
			return window.location.pathname !== '/admin/full-screen-maps';

	
		}
	
	 
	
		
	  };



	
	const getActiveRoute = (routes) => {
		let activeRoute = '';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} 
			
			else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
				
					if(routes[i].name  == "Test")
					{	
						return routes[i].name;


					}
					else{

						return routes[i].name ;

					}
					return routes[i].name;
				}
				
				else if (window.location.href.indexOf('/admin/user/') !== -1) {
					return props.default_language.edit_user;
				}
				else{
				
					
												
					
					

				}
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondary;
				}
			}
		}
		return activeNavbar;
	};
	const getActiveNavbarText = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].messageNavbar;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {

		return routes.map((prop, key) => {
		  // Check if the route layout matches and profileData role is 'student'
		  if (prop.layout === '/user' && profileData?.role === 'student') {
				if(prop.path == '/test/:testid')
				{
					return (
						<Route
						  path={prop.layout + prop.path}
						  key={key}
						  render={(routeProps) => (
							<Test {...routeProps} profileData={profileData} setCustomActiveRoute={setCustomActiveRoute} />
						  )}
						/>
					  ); 
				}
				if(prop.path == '/profile')
					{
						return (
							<Route
							  path={prop.layout + prop.path}
							  key={key}
							  render={(routeProps) => (
								<Profile {...routeProps} profileData={profileData} reFetchProfileData={()=>reFetchProfileData} setCustomActiveRoute={setCustomActiveRoute} />
							  )}
							/>
						  ); 
					}
				else{
					return (
						<Route
						  path={prop.layout + prop.path}
						  key={key}
						  render={(routeProps) => (
							<prop.component {...routeProps} profileData={profileData} default_language={default_language} />
						  )}
						/>
					  );

				}
			return (
			  <Route
				path={prop.layout + prop.path}
				key={key}
				render={(routeProps) => (
				  <prop.component {...routeProps} default_language={default_language} profileData={profileData} />
				)}
			  />
			);
		  }
		  
		  // Recursively handle nested routes
		  if (prop.collapse || prop.category) {
			return getRoutes(prop.items);
		  }
		  
		  // If none of the conditions match, return null
		  return null;
		});
	  };
	  


	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();
	document.documentElement.dir = 'ltr';
	return (
		<Box>
			<Box>
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar
					}}>
					<Sidebar {...rest} routes={activeRoutes} display='none'  />
					<Box
						float='right'
						minHeight='100vh'
						height='100%'
						overflow='auto'
						position='relative'
						maxHeight='100%'
						w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
						transitionDuration='.2s, .2s, .35s'
						transitionProperty='top, bottom, width'
						transitionTimingFunction='linear, linear, ease'>
						<Portal>
							<Box>
								<Navbar
									onOpen={onOpen}
									logoText={'SuvvyAtor'}
									brandText={customActiveRouteName}
									secondary={getActiveNavbar(routes)}
								    message={getActiveNavbarText(routes)}
									fixed={fixed}
									profileData={profileData}
									{...rest}
								/>
							</Box>
						</Portal>

						{getRoute() ? (
							<Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
								<Switch>
								
									{getRoutes(routes)}
									<Redirect from='/' to='/user/default' />

								</Switch>
							</Box>
						) : <Redirect from='/' to='/auth' />}
						<Box>
							<Footer />
						</Box>
					</Box>
				</SidebarContext.Provider>
			</Box>


		</Box>
	);
}
