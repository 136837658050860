/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Badge, Flex, Text, Button,Stack, SimpleGrid, FormControl, FormLabel, Input, Select, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import UsersTable from "views/admin/users/components/UsersTable";
import {
  
  usersColumnsData,

} from "views/admin/users/variables/columnsData";
import tableDataDevelopment from "views/admin/users/variables/tableDataDevelopment.json";
import React, {useState, useEffect} from "react";
import Helpers from "helpers";
import { useParams } from 'react-router-dom';
import countryCodes from 'config/country_codes';
import Card from "components/card/Card";

export default function Users(props) {
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({});
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [dialCode, setDialCode] = useState("");

  let handle  = useParams();

  const onUpdateUserClick = () =>
  {

    setIsSavingLoading(true);
    Helpers.updateUser(userData).then((response)=>{
      console.log(response);
      setUserData(response);
      setIsSavingLoading(false);

    });

  }

  const  getDialCode = (countryCode)=> {
    

    const country = countryCodes.find(country => country.code === countryCode);
    return country ? country.dial_code : null;
}
  useEffect(()=>{

    setDialCode(getDialCode(userData?.address?.country));

    
  },[userData?.address?.country])

  useEffect(()=>{


    console.log("all of our params");
    console.log(handle);
  Helpers.getUser(userId).then((response)=>{
    console.log(response);
    setUserData(response);

  });
  setDialCode(getDialCode(userData?.address?.country));



  }, [userId]);



  useEffect(()=>{

if(handle.userId)
{
  setUserId(handle.userId);
}



  }, [handle]);


  useEffect(()=>{

    Helpers.getRoles().then((response)=>{
      console.log(response);
      setRoles(response);
  
    });
  
    
      }, [userId]);


  

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>

<Card>

<SimpleGrid
        mb='20px'
        columns={{ sm: 4, md: 6 }}
        spacing={{ base: "20px", xl: "20px" }}>



<Box>
<Flex>
  <Box ml="3">
    <Text fontWeight="bold">
    {userData?.firstName} {userData?.lastName}
      <Badge ml="1" colorScheme="green" style={!Helpers.dateWithinLast15Days(userData?.registrationDate) ? {display:'none'}:{}}>
        New
      </Badge>
    </Text>
  </Box>
</Flex>
  </Box>
<Box style={{margin:'auto'}}>
<Badge ml="1" colorScheme="green">
{Helpers.formatTimestamp(userData?.registrationDate)}
      </Badge>
        

        </Box>
  
        <Box style={{margin:'auto'}}>

<Button variant="brand" size="md" isLoading={isSavingLoading} loadingText={props.default_language.saving} onClick={()=>onUpdateUserClick()}>
    {props.default_language.update_user}
  </Button>
  </Box>
  <Box style={{margin:'auto'}}>

  <Button variant="brand" size="md" isLoading={isDeletingLoading} loadingText={props.default_language.deleting}>
    {props.default_language.delete_user}
  </Button>
</Box>
 



</SimpleGrid>


<FormControl id="first-name" isRequired>
  <FormLabel>{props.default_language.firstName}</FormLabel>
  <Input placeholder="First name" borderRadius="16px" value={userData?.firstName} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    firstName: event.target.value,
}))} />
</FormControl>
<FormControl id="last-name" isRequired>
  <FormLabel>{props.default_language.lastName}</FormLabel>
  <Input placeholder="First name" borderRadius="16px" value={userData?.lastName} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    lastName: event.target.value,
}))} />
</FormControl>
<FormControl id="email" isRequired>
  <FormLabel>{props.default_language.email}</FormLabel>
  <Input placeholder="Email" borderRadius="16px" value={userData?.email} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    email: event.target.value,
}))} />
</FormControl>
<FormControl id="country" >
  <FormLabel>{props.default_language.country}</FormLabel>
  <Select placeholder={props.default_language.select_country} value={userData?.address?.country} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, country:event.target.value},
}))}>
    {countryCodes.map((country)=>{
      return (  
      <option value={country.code}>{country.name}</option>
      )

    })}
  
</Select>

</FormControl> 
<FormControl id="phone" >
<FormLabel>{props.default_language.phone}</FormLabel>

<InputGroup>
    <InputLeftAddon children={dialCode} borderRadius="16px" />
    <Input type="tel" placeholder={props.default_language.phone} borderRadius="16px" value={userData?.phoneNumber} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    phoneNumber: event.target.value,
}))} />
  </InputGroup>
  </FormControl>

<FormControl id="street" >
  <FormLabel>{props.default_language.street_address}</FormLabel>
  <Input placeholder={props.default_language.street} borderRadius="16px" value={userData?.address?.street} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, street:event.target.value},
}))} />
</FormControl>
<FormControl id="city" >
  <FormLabel>{props.default_language.city}</FormLabel>
  <Input placeholder={props.default_language.city} borderRadius="16px" value={userData?.address?.city} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, city:event.target.value},
}))} />
</FormControl>
<FormControl id="state" >
  <FormLabel>{props.default_language.state}</FormLabel>
  <Input placeholder={props.default_language.state} borderRadius="16px" value={userData?.address?.state}  onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, state:event.target.value},
}))} />
</FormControl>
<FormControl id="postalCode" >
  <FormLabel>{props.default_language.postal_code}</FormLabel>
  <Input placeholder={props.default_language.postal_code} borderRadius="16px" value={userData?.address?.postalCode} onChange={(event)=>setUserData(prevState => ({
    ...prevState,
    address: {...prevState.address, postalCode:event.target.value},
}))} />
</FormControl>


<FormControl id="role" >
  <FormLabel>{props.default_language.role}</FormLabel>
  <Select placeholder={props.default_language.role} value={userData?.role} onChange={(event)=> { setUserData(prevState => ({
    ...prevState,
    role: event.target.value,
}));  } }>
    {roles && roles.map(role=>{
      return (  
      <option value={role}>{role}</option>
      )

    })}
  
</Select>

</FormControl> 

<FormControl id="default_answers_status" style={userData?.role == 'moderator' ?  {}: {'display':'none'}}>
  <FormLabel>{props.default_language.default_answers_status}</FormLabel>
  <Select placeholder={props.default_language.default_answers_status} value={userData?.defaultAnswersStatus} onChange={(event)=> { setUserData(prevState => ({
    ...prevState,
    defaultAnswersStatus: event.target.value,
}));  } }>
        <option value="active">{props.default_language.active}</option>
        <option value="pending">{props.default_language.pending}</option>

  
</Select>

</FormControl> 

</Card>
        
       
      </SimpleGrid>
    </Box>
  );
}
