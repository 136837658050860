import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Link,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Links = ['About Us', 'Contact Us'];

const NavLink = ({ children }) => {
  const linkHoverBg = useColorModeValue('gray.200', 'gray.700');
  const linkColor = useColorModeValue('gray.800', 'gray.200');

  return (
    <Link
      px={3}
      py={2}
      rounded="md"
      fontWeight="bold"
      fontSize="sm"
      color={linkColor}
      _hover={{
        textDecoration: 'none',
        bg: linkHoverBg,
        transform: 'scale(1.05)',
        transition: 'all 0.2s ease-in-out',
      }}
      href={'#'}
    >
      {children}
    </Link>
  );
};

export default function ModernNavBar(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Move useColorModeValue hooks outside of conditional rendering
  const bg = useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(26, 32, 44, 0.8)');
  const boxShadow = useColorModeValue('lg', 'dark-lg');
  const loginHoverBg = useColorModeValue('teal.100', 'teal.800');
  const signUpHoverBg = useColorModeValue('teal.500', 'teal.300');

  return (
    <Box
      bg={bg}
      backdropFilter="saturate(180%) blur(10px)"
      boxShadow={boxShadow}
      px={4}
      pos="fixed"
      w="100%"
      zIndex={100}
    >
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={'center'}>
          <img src='/logo.png' width="240px"/>
          <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={'center'}>
          {!props.token && (
            <>
              <Button
                variant={'ghost'}
                colorScheme={'teal'}
                size={'sm'}
                mr={4}
                _hover={{
                  bg: loginHoverBg,
                }}
              >
                <Link href="#/auth/sign-in">Login</Link>
              </Button>
              <Button
                variant={'solid'}
                colorScheme={'teal'}
                size={'sm'}
                mr={4}
                _hover={{
                  bg: signUpHoverBg,
                  transform: 'translateY(-2px)',
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <Link href="#/auth/sign-up">Sign Up</Link>
              </Button>
            </>
          )}
          {props.token && (
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                <Avatar size={'sm'} src={' '} />
              </MenuButton>
              <MenuList>
                <MenuItem as="a" href="#/user/default">
                  My Account
                </MenuItem>
                <MenuDivider />
                <MenuItem>Logout</MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
