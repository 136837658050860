// Chakra imports
import {
    Box,
    Flex,
    Text,
    HStack,
    Badge,
    Button,
    VStack,
    Tag,
    Icon,
    Input,
    useColorModeValue,
    Checkbox,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import Menu from "components/menu/MainMenu";
  import IconBox from "components/icons/IconBox";
  import {NavLink, useHistory} from 'react-router-dom';
  import Pagination from "./Pagination.js";

  // Assets
  import { MdCheckBox, MdDragIndicator, MdBook, MdNewLabel } from "react-icons/md";
  import React from "react";
  import Helpers from '../../../../helpers';
  export default function Questions(props) {
    const { ...rest } = props;
    const history = useHistory();

    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
    const brandColor = useColorModeValue("brand.500", "brand.400");
    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}  style={{marginTop:10}}>
        <Flex alignItems='center' w='100%' mb='30px' justifyContent={"space-between"}>
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            icon={<Icon as={MdBook} color={brandColor} w='24px' h='24px' />}
          />
  
          <Text color={textColor} fontSize='xl' fontWeight='700'>
            Search Questions
          </Text>

          <Input value={props?.filters?.search} type={'text'} onChange={(e)=>props.onSearchChange(e.target.value)} w={'50%'} ml={2} />
        <Text alignItems={'flex-end'} ml={2}>Results : {props.totalResults}</Text>
        <Text alignItems={'flex-end'} ml={2}>Pages : {props.totalPages}</Text>

        { /* <Menu ms='auto' /> */}
        </Flex>
        <Box px='11px'>
         
            { props?.questions?.length > 0 && props?.questions.map((test)=>{
                return (<Flex flexWrap={"wrap"} justifyContent={'space-between'}     onClick={()=>history.push('/user/test/'+test._id) } justifyContent={"space-between"} mb='20px' _hover={{cursor:'pointer', opacity:0.6}} style={{borderBottomWidth:1, padding:10}} > 
                   <HStack>

                   <Badge colorScheme='green'>
        {'#'+  test.internal_id}
    </Badge>
                    <VStack alignItems={'left'} textAlign={"left"} justifyContent={'left'} flexWrap={'wrap'}>

<HStack justifyContent={'left'} flexWrap={'wrap'} gap={3}>




  </HStack>

  <HStack justifyContent={'left'}>

    {test.successRate && <Badge colorScheme={test.successRate > 80 ? 'green': 'red'}>
        {test.successRate+"%"} Success
    </Badge>}


  




<Text  textAlign='end' >
{test?.text.replace(/<[^>]*>?/gm, '')}
</Text>
</HStack>
</VStack>
                   </HStack>
                 
                   
                    </Flex>);





            })}

<Pagination totalPages={props?.totalPages}
          totalResults={props?.totalResults}
          currentPage={props?.filters?.page}
          onPageChange={props?.onPageChange}/>

            {props?.questions == 0 && <Box>

              <Text>Try to search for some questions....</Text>
              
              </Box>}
         


         
        </Box>
      </Card>
    );
  }
  