/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

import BigButton from "components/card/BigButton";
import {NavLink, useHistory} from 'react-router-dom';

import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdNewLabel,
  MdHistory,
  MdCalendarMonth

} from "react-icons/md";
import Tests from "views/user/default/components/Tests";
import PlansLayout from "views/user/default/components/Plans";
import Questions from "views/user/default/components/Questions";
import Flags from "views/user/default/components/Flags";

import default_language from "config/default_language";

import {useState, useEffect} from 'react';

import Helpers from "../../../helpers";
import { question } from "config/default_language";
export default function UserReports({profileData}) {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const history = useHistory();

  const [dashboardData, setDashboardData] = useState();
  const [tests, setTests] = useState([]);
  const [plans, setPlans] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionFilters, setQuestionFilters] = useState({page :1, search :'', study : '', subject : ''});
  const [flagFilters, setFlagFilters] = useState({page :1, mark:false, review:false, green_flag:false, yellow_flag:false, red_flag:false});
  const [nextQuestionSettings, setNextQuestionSettings] = useState(Helpers.getNextQuestionsSettings());
  const [flags, setFlags] = useState([]);

  const [flagsTotalPages, setFlagsTotalPages] = useState(0);
  const [flagsTotalResults, setFlagsTotalResults] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  useEffect(()=>{
      if(Helpers.isTokenExpired)
      {    console.log("dddddd");

  
      
      }

      loadDashboardData();
      loadTests();
  }, []);


  useEffect(()=>{


      Helpers.toggleNextQuestions();
    

  },[nextQuestionSettings])

  const getQuestionsForFilters = async() =>{

   const questionsResponse = await Helpers.getQuestions(questionFilters.page, questionFilters.search, questionFilters.study, questionFilters.subject);
   console.log("response is",questionsResponse);

   if(questionsResponse)
   {
    setQuestions(questionsResponse?.questions)
    setTotalPages(questionsResponse?.totalPages)
    setTotalResults(questionsResponse?.totalResults)

   }

  }

  const deleteAllFlags = async () => {
    const confirmation = window.confirm("Are you sure you want to delete all of your flagged questions?");
    
    if (confirmation) {
      await Helpers.deleteAllFlags();
      alert("All flagged questions have been deleted.");
    } else {
      alert("Action canceled.");
    }
  };

  const getFlaggedQuestions = async() =>{

    const questionsResponse = await Helpers.getAllFlags(flagFilters);
    console.log("response is",questionsResponse);
 
    if(questionsResponse)
    {
     setFlags(questionsResponse.data)
     setFlagsTotalPages(questionsResponse?.meta?.totalPages);
     setFlagsTotalResults(questionsResponse?.meta?.totalFlags);

     //setTotalResults(questionsResponse?.totalResults)
 
    }
 
   }

  useEffect(()=>{
    getQuestionsForFilters();
    console.log(questionFilters);
  },[questionFilters])

  useEffect(()=>{
    getFlaggedQuestions(flagFilters);
  },[flagFilters])

  

const getPlans = async ()=>{
  const plansResponse  = await Helpers.getPlans();
  if(plansResponse)
  {
    console.log('our plans are', plansResponse);
    setPlans(plansResponse);
  }
  getFlaggedQuestions();
}

  useEffect(()=>{
    getPlans();
}, []);


  const loadDashboardData = async() =>{

    try{
      await Helpers.getDashboard().then((response)=>{
        if(response)
        {
          setDashboardData(response)
        //  setUsersTable(response.users);

        }
        console.log(response);
      });
    }
    catch(error) {


    }
  }

  const deleteTest = async(testId) =>{

    await Helpers.deleteTest(testId);
    loadTests();

  }

  const handleFlagsFiltersChange = async(name, value) =>
  {

    if(name =="mark")

    {
      setFlagFilters(prevState=>({...prevState, mark:value}));
    }
    if(name =="review")

      {
        setFlagFilters(prevState=>({...prevState, review:value}));
      }
      if(name =="green_flag")

        {
          setFlagFilters(prevState=>({...prevState, green_flag:value}));
        }
        if(name =="red_flag")

          {
            setFlagFilters(prevState=>({...prevState, red_flag:value}));
          }
          if(name =="yellow_flag")

            {
              setFlagFilters(prevState=>({...prevState, yellow_flag:value}));
            }

  }

  const loadTests = async() =>{
    try{
      const testsResponse = await Helpers.getTests();
      console.log("our tests", testsResponse);


      if(testsResponse)
      {
        setTests(testsResponse);
      }
    }
    catch(error)
    {
      console.log("something wrong herre", error);
    }

      
  }
  return (



    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      {profileData?.activePlans.length > 0  && 

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>

          <MiniStatistics

          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdNewLabel} color={brandColor} />
              }
            />}

            name={"Create a new Test"}
          value='New Test'
          onClick={()=>history.push('/user/newtest')}        

          >

            
        
          </MiniStatistics  >


          <MiniStatistics

startContent={
  <IconBox
    w='56px'
    h='56px'
    bg={boxBg}
    icon={
      <Icon w='32px' h='32px' as={MdNewLabel} color={brandColor} />
    }
  />}

  name={"Reset all"}
value='Flags'
onClick={()=> deleteAllFlags()}        

>

  

</MiniStatistics  >















        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdCalendarMonth} color={brandColor} />
              }
            />
          }
          name={"Month Tests"}
          value='5'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name={default_language.total_questions}
          value={dashboardData?.totalQuestions}
        />
        <MiniStatistics  name={default_language.databases} value={dashboardData?.totalStudies} />
        
        
        <MiniStatistics
          startContent={
        
            <Checkbox onChange={()=>setNextQuestionSettings(!nextQuestionSettings)} isChecked={nextQuestionSettings}  width="48px"  height="48px"  style={
              {margin:'auto'}
            }
           >
              
            </Checkbox>
          }
          name={"Go to Next Question"}
          value='Enabled'
        />
   

      </SimpleGrid>}
      {profileData?.activePlans.length > 0  && 
      <>
         <Tests tests={tests} deleteTest={(testId)=>deleteTest(testId)} />
        <Questions onPageChange={(page)=>setQuestionFilters((prevState=>({...prevState, page:page})))} totalPages={totalPages}  totalResults={totalResults} filters={questionFilters} questions={questions} onSearchChange={(value)=>setQuestionFilters((prevState=>({...prevState, search:value})))} />
        <Flags onPageChange={(page)=>setFlagFilters((prevState=>({...prevState, page:page})))} totalPages={flagsTotalPages}  totalResults={flagsTotalResults} filters={flagFilters} onFilterChange={(name, value)=>handleFlagsFiltersChange(name, value)} questions={flags} onSearchChange={(value)=>setFlagFilters((prevState=>({...prevState, search:value})))} />

      </>
     
        
        
        
        }




{profileData?.activePlans.length == 0  && 
<Box>
{plans.length > 0 && <PlansLayout profileData={profileData} plans={plans}/>}
  </Box>

}

     





   
    </Box>
  );
}
