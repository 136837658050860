/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Image } from "@chakra-ui/react"
import {useHistory} from 'react-router-dom';

import { Center,InputRightElement, Box, Badge, Flex, VStack, Text, Button,Stack, List,Tag, ListItem, ListIcon, SimpleGrid, FormControl, FormLabel, Input, Select, InputGroup, InputLeftAddon, Spacer } from "@chakra-ui/react";
import UsersTable from "views/admin/users/components/UsersTable";
import {
  
  usersColumnsData,

} from "views/admin/users/variables/columnsData";
import 'react-quill/dist/quill.snow.css';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import tableDataDevelopment from "views/admin/users/variables/tableDataDevelopment.json";
import React, {useState, useEffect} from "react";
import Helpers from "helpers";
import { useParams } from 'react-router-dom';
import countryCodes from 'config/country_codes';
import Card from "components/card/Card";
import { queryByTestId } from "@testing-library/react";
import { Grid, GridItem } from "@chakra-ui/react"
import { IconButton } from "@chakra-ui/react"
import { DeleteIcon, QuestionOutlineIcon, AddIcon, InfoIcon, CheckIcon, WarningTwoIcon, ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
import ReactQuill from 'react-quill';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import helpers from "helpers";
import EditorToolbar, { modules,  formats } from "./components/CustomToolbar";
import default_language from "config/default_language";
import { defaultColumn } from "react-table";
import './style.css';

export default function EditPlan(props) {

  const [planId, setPlanId] = useState("");
  const [newStudyName, setNewStudyName] = useState("");
  const [newSubjectName, setNewSubjectName] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [planData, setPlanData] = useState({});
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [isAddingStudyLoading, setIsAddingStudyLoading] = useState(false);
  const [isAddingSubjectLoading, setIsAddingSubjectLoading] = useState(false);
  const [nextPrev, setNextPrev] = useState({});

  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [dialCode, setDialCode] = useState("");
  const [backgroundColor, setBackGroundColor] = useState("");
  const [studies, setStudies] = useState([]);
  const [subjects, setSubjects] = useState({});
  const [subSubjects, setSubSubjects] = useState({});
  const [subSubSubjects, setSubSubSubjects] = useState({});

  const [addNewStudyOpened, setAddNewStudyOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

    const history = useHistory();

  let handle  = useParams();


  useEffect(()=>{


    console.log(handle);
  Helpers.getStudies().then((response)=>{
    console.log("studies are", response);
    setStudies(response);

  });


 



  }, []);


  


  
  const handleCheckboxChange = (selectedIds) => {
    // Update the planData state with the selected study IDs

    setPlanData((prevState) => ({ ...prevState, study: selectedIds }));

  };

  



  function getStudyTitlebyId(id) {
    // Loop through the array
    for (const item of studies) {
      // Check if the object's _id matches the provided id
      if (item._id === id) {
        // If there's a match, return the title
        return item.title;
      }
    }
    // If no match is found, return undefined
    return undefined;
  }

  const onUpdatePlanClick = () =>
  {

    setIsSavingLoading(true);
    Helpers.updatePlan(planData).then((response)=>{

      setPlanData(response);
      setIsSavingLoading(false);
      setSuccess(true);
      setTimeout(function() {
        setSuccess(false);

    }, 3000);
     

    });

  }
  const onAddNewStudy = () =>
  {

    setIsAddingStudyLoading(true);
    Helpers.addStudy({title:newStudyName, desription:""}).then((response)=>{
      //setQuestionData(response);
      setIsAddingStudyLoading(false);

    });

  }






  useEffect(()=>{

if(handle.planId)
{
  setPlanId(handle.planId);
  Helpers.getPlan(handle.planId).then(async (response)=>{
      console.log("plan data", response);

        setPlanData(response);
       
      });


}



  }, [handle]);

 




  const handleAddOption = () => {
    const updatedFeatures = [...planData.features];

    updatedFeatures.push("");

    setPlanData((prevState) => ({ ...prevState, features: updatedFeatures }));

  };

  const handleRemoveOption = (index) => {
    setPlanData((prevState) => {
      const updatedFeatures = [...prevState.features];
      updatedFeatures.splice(index, 1);
  
    
  
      return { ...prevState, features: updatedFeatures };
    });
  };



  // Chakra Color Mode
  return (
    <Flex>
    <Box w={{ sm: "100%", md: "100%", lg:"100%" }} display={{md:'flex', sm:'block'}} gap={2} alignItems='baseline'>
    <Box w={{ sm: "100%", md: "80%", lg:"80%" }} >
          <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
          mb='20px'
          columns={{   sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>





          <Card >

       
       
         


         {planData && <FormControl  isRequired >
          <FormLabel>Plan Name</FormLabel>

      

          <Input  placeholder={props.default_language.question} borderRadius="16px" value={planData?.name} onChange={(event)=>setPlanData(prevState => ({
          ...prevState,
          name: event.target.value,
          }))} />



          </FormControl>}

          {planData && <FormControl  isRequired >
          <FormLabel>Price</FormLabel>

      

          <Input  placeholder="Price" borderRadius="16px" value={planData?.price} onChange={(event)=>setPlanData(prevState => ({
          ...prevState,
          price: event.target.value,
          }))} />



          </FormControl>}


          {planData && <FormControl  isRequired >
          <FormLabel>Sale Price</FormLabel>

      

          <Input  placeholder="Sale Price" borderRadius="16px" value={planData?.sale_price} onChange={(event)=>setPlanData(prevState => ({
          ...prevState,
          sale_price: event.target.value,
          }))} />



          </FormControl>}


          <FormControl >
  <FormLabel>Interval</FormLabel>
  
    <Select value={planData?.interval} onChange={(event)=>  setPlanData((prevState) => ({ ...prevState, interval: event.target.value }))}>
      <option value="daily">Daily</option>
      <option value="weekly">Weekly</option>

      <option value ="monthly">Monthly</option>
      <option value="yearly">Yearly</option>


    </Select>



  


</FormControl> 

        

          <Card>

          {planData.features &&  planData.features.map((feature, index) =>  
          <Flex align={"center"} grow={2}>
         
          <Box w={'80%'} align={'center'} >

          <FormControl  isRequired style={{margin:20}} >

          <FormLabel>Feature</FormLabel>

            
          <Input  placeholder={props.default_language.answer} borderRadius="16px" value={feature} onChange={(event) => {
          setPlanData((prevState) => ({
          ...prevState,
          features: prevState.features.map((feature, indexmap) =>
          index === indexmap
          ? event.target.value
          : feature
          ),
          }));
          }}/>



          
          </FormControl>

          </Box>
          <Box w={'10%'} align={'center'}>
          <IconButton
          aria-label={props.default_language.delete_answer}
          borderRadius="10px"
          onClick={() => handleRemoveOption(index)}
          icon={<DeleteIcon />}
          />

          </Box>



          </Flex>

          )
          }

          </Card>
          <Button onClick={handleAddOption}  variant="brand" size="md"       
>Add Feature</Button>

       
         
         
        

            


          </Card>

          


          </SimpleGrid>
          </Box>

   

    </Box>

    <Box w={{ sm: "100%", md: "20%" }} pt={{ base: "130px", md: "80px", xl: "80px" }}>





    <CheckboxGroup 
      value={planData.study} 
      onChange={handleCheckboxChange}
    >
      <VStack align="start">
        {studies.map((study) => (
          <Checkbox key={study._id} value={study._id}>
            {study.title}
          </Checkbox>
        ))}
      </VStack>
    </CheckboxGroup>


    





<Card>
<Alert status='success' style={(success)? {} : {'display':'none'}}>
  <AlertIcon />
  <AlertTitle>Question Updated</AlertTitle>
</Alert>
  <Button variant="brand" isDisabled={ (error ) ? true :false } isLoading={isSavingLoading} onClick={()=>onUpdatePlanClick()}>Update Plan</Button>
</Card>








    </Box>
    </Box>
      
      </Flex>
  );
}
