/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import LogsTable from "views/admin/logs/components/LogsTable";
import {
  
  logsColumnData,

} from "views/admin/logs/variables/columnsData";
import tableDataDevelopment from "views/admin/questions/variables/tableDataDevelopment.json";
import React, {useState, useEffect} from "react";
import Helpers from "helpers";

export default function Logs(props) {
  const [page, setPage] = useState(Helpers.getStorage('Logspage')? Helpers.getStorage('Logspage') :1 );
  const [logs, setLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState(Helpers.getStorage('Logssearch'));
  const [study, setStudy] = useState(Helpers.getStorage('Logsstudy'));
  const [subject, setSubject] = useState(Helpers.getStorage('Logssubject'));
  const [subSubject, setSubSubject] = useState(Helpers.getStorage('LogssubSubject'));
  const [subSubSubject, setSubSubSubject] = useState(Helpers.getStorage('LogssubSubSubject'));
  const [user, setUser] = useState(Helpers.getStorage('Logsuser'));
  const [action, setAction] = useState(Helpers.getStorage('Logsaction'));
  const [date, setDate] = useState(Helpers.getStorage('Logsdate') ? Helpers.getStorage('Logsdate'): new Date().toISOString().slice(0, 10));
  const [totalLogs, setTotalLogs] = useState(0);
  const [dateTo, setDateTo] = useState( Helpers.getStorage('LogsdateTo') ? Helpers.getStorage('LogsdateTo'): new Date().toISOString().slice(0, 10));

  const [studies, setStudies] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [subSubjects, setSubSubjects] = useState([]);
  const [subSubSubjects, setSubSubSubjects] = useState([]);
  const [users, setUsers] = useState([]);

  const [withRealExams, setWithRealExams] = useState(null);

  useEffect(()=>{
  Helpers.getLogs(page, searchValue, user,  action, date, dateTo, study, subject).then((response)=>{
    if(response.logs)
    {
      setLogs(response.logs);
      setTotalPages(response.totalPages);
      setTotalLogs(response.totalLogs);
      Helpers.setStorage('Logsuser', user);
      Helpers.setStorage('Logssearch', searchValue);
      Helpers.setStorage('Logssubject', subject);
      Helpers.setStorage('Logsaction', action);
      Helpers.setStorage('Logsstudy', study);
      Helpers.setStorage('Logspage', page);
      Helpers.setStorage('Logsdate', date);
      Helpers.setStorage('LogsdateTo', dateTo);

    }
  

  });


  }, [page, searchValue, user, action, date, dateTo, study, subject]);



  useEffect(()=>{


    Helpers.getStudies().then((response)=>{
      console.log(response);
      setStudies(response);
  
    });
  
  
   
  
  
  
    }, []);
  
    useEffect(()=>{

      if(study)
      {
        Helpers.getSubjects(study).then((response)=>{
          console.log("subjects are");
          console.log(response);
          setSubjects(response);
      
        });
      }
      else{
  
        setSubjects([]);
        setSubSubjects([]);
        setSubSubSubjects([]);
  
      }
  
    
    
     
    
    
    
      }, [study]);
useEffect(()=>{

  Helpers.getUsers().then((response)=>{
    if(response.users)
    {
      console.log('our users are',response.users );
      setUsers(response.users);
    }
  

  });

}, []);
  





  


     

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <LogsTable
          columnsData={logsColumnData}
          tableData={logs}
          title={props.default_language.logs}
          onChangeAction={(value)=> {setAction(value); setPage(1);setSubject(""); setSubSubject(""); setSubSubSubject("");  }}
          onChangeDate={(value)=> {setDate(value); setPage(1);setSubject(""); setSubSubject(""); setSubSubSubject("");  }}
          onChangeDateTo={(value)=> {setDateTo(value); setPage(1);setSubject(""); setSubSubject(""); setSubSubSubject("");  }}

          onSearch={(value)=> {setSearchValue(value); setPage(1);setSubject(""); setSubSubject(""); setSubSubSubject("");  }}
          onChangeStudy={(value)=> {setStudy(value); setPage(1); }}
          onChangeSubject={(value)=> {setSubject(value); setPage(1); setSubSubject(""); setSubSubSubject(""); }}
          onChangeUser={(value)=> {setUser(value); setPage(1);  }}
          users={users}
          searchValue={searchValue}
          onChangeSubSubject={(value)=> {setSubSubject(value); setPage(1);  setSubSubSubject(""); }}
          onChangeSubSubSubject={(value)=> {setSubSubSubject(value); setPage(1); }}
          subject={subject}
          subSubject={subSubject}
          subSubSubject={subSubSubject}
          study={study}
          user={user}
          action={action}
          date={date}
          dateTo={dateTo}

         totalLogs = {totalLogs}
          studies={studies}
          subjects={subjects}
          subSubjects={subSubjects}
          subSubSubjects = {subSubSubjects}
          totalPages={totalPages}
          currentPage= {page}
          onPageChange={(value)=>setPage(value)}
          onRealExamsChange={ (value)=> { setWithRealExams(value); setPage(1) ;}}


        />
        
       
      </SimpleGrid>
    </Box>
  );
}
