/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;
  const { ...rest } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
   // console.log("route name is");
    //console.log(routeName)
    return location.pathname.includes(routeName);
   // console.log(window.location.href.slice(window.location.href.lastIndexOf("/") , window.location.href.length));
  
    //return window.location.href.slice(window.location.href.lastIndexOf("/") , window.location.href.length).includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight='bold'
              mx='auto'
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt='18px'
              pb='12px'
              key={index}>
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/user" ||

        route.layout === "/rtl"
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.icon && !route.exclude_from_menu  ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : null}
            {route.hasChildren && <ul style={{marginLeft:10}} >
{route.children.map((children, indexchild) => {
if (!route.exclude_from_menu) {
  return <NavLink  key={100+indexchild} to={children.layout + children.path}>             
  <Box>
            <HStack
              spacing={
                activeRoute(children.path.toLowerCase()) ? "22px" : "26px"
              }
              py='5px'
              ps='10px'>
              <Flex w='100%' alignItems='center' justifyContent='center'>
                <Box
                  color={
                    activeRoute(children.path.toLowerCase())
                      ? activeIcon
                      : textColor
                  }
                  me='18px'>
                  {children.icon}
                </Box>
                <Text
                  me='auto'
                  color={
                    activeRoute(children.path.toLowerCase())
                      ? activeColor
                      : textColor
                  }
                  fontWeight={
                    activeRoute(children.path.toLowerCase())
                      ? "bold"
                      : "normal"
                  }>
                  {children.name}
                </Text>
              </Flex>
              <Box
                h='36px'
                w='4px'
                bg={
                  activeRoute(children.path.toLowerCase())
                    ? brandColor
                    : "transparent"
                }
                borderRadius='5px'
              />
            </HStack>
          </Box>
</NavLink>
}



})}

  
 
</ul>}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
