import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Heading,
    Select,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Checkbox,
    Stack,
    FormControl,
    FormLabel,
    useColorModeValue
  } from '@chakra-ui/react';
import Card from '../../../../components/card/Card';
import {NavLink, useHistory} from 'react-router-dom';


import Helpers from '../../../../helpers';

const Test = ({profileData}) => {
  const history = useHistory();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBgnew = useColorModeValue("white", "whiteAlpha.100");


  const [showOptions, setShowOptions] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const [studies, setStudies] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subSubjects, setSubSubjects] = useState([]);
  const [subSubSubjects, setSubSubSubjects] = useState([]);

  const [selectedStudy, setSelectedStudy] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedSubSubjects, setSelectedSubSubjects] = useState([]);

  const [filters, setFilters] = useState({
    onlyRealExam: false,
    withImages:false,
    markFlagged:false,
    reviewFlagged:false,
    greenFlagged: false,
    redFlagged: false,
    withNotes: false,
    incorrectAnswers: false,
  });

  const handleFilterChange = (filter) => {
    setFilters({
      ...filters,
      [filter]: !filters[filter],
    });
  };

  const handleStartClick = (mode) => {
    setSelectedMode(mode);
    setShowOptions(true);
  };

  const handleStartTestClick = async() =>{

    var filtersQuery = [];


    if(filters.greenFlagged)
    {
      filtersQuery.push({name:"green_flag", value:true});

    }
    if(filters.redFlagged)
      {
        filtersQuery.push({name:"red_flag", value:true});
  
      }
      if(filters.yellowFlagged)
        {
          filtersQuery.push({name:"yellow_flag", value:true});
    
        }

        if(filters.markFlagged)
          {
            filtersQuery.push({name:"mark", value:true});
      
          }
          if(filters.reviewFlagged)
            {
              filtersQuery.push({name:"review", value:true});
        
            }

            if(filters.onlyRealExam)
            {
              filtersQuery.push({name:"realExam", value:true});

            }
            if(filters.withImages)
              {
                filtersQuery.push({name:"withImages", value:true});
  
              }


    const query = {study:selectedStudy, subjects:selectedSubSubjects.length > 0? selectedSubSubjects :[selectedSubject]  , filters: filtersQuery};
    console.log(query);
    //const filters = {subjects:selectedSubSubjects};
    const testResponse  = await Helpers.addTest(query);
    
    if(testResponse)
    {
      history.push('/user/test/'+testResponse.id);
    }
    else{
      alert("Something gone wrong!")
    }


  }




  const subSubjectChange = async (subjectId, children = []) =>{


        const alreadyIncluded = selectedSubSubjects.length && selectedSubSubjects.includes(subjectId);

        
      
        if(alreadyIncluded)
        {
          if(children.length > 0)
            {
              var childrenIds =  children.map(subject => subject._id);
              childrenIds.push(subjectId);

              setSelectedSubSubjects((prevState) => 
                prevState.filter(id => !childrenIds.includes(id))
              );
    
            }
            else{
              setSelectedSubSubjects((prevState=>prevState.filter(id => id!=subjectId)))
            }


           
        }
        else{


            if(selectedSubSubjects.length)

            {      

              if(children.length > 0)
                {
                  var childrenIds =  children.map(subject => subject._id);
                  childrenIds.push(subjectId);
    
                  setSelectedSubSubjects((prevState=>[...prevState, ...childrenIds]))

        
                }
                else{
                  setSelectedSubSubjects((prevState=>[...prevState, subjectId]))

                }

              }    
              else{
                setSelectedSubSubjects((prevState=>[subjectId]))

              }        


            }

                console.log(selectedSubSubjects);

  }


  useEffect(()=>{

    loadInitial();


  },[])


  useEffect(()=>{

    if(selectedStudy)
    {
        getSubjectsForStudy();
    }


  },[selectedStudy])


  useEffect(()=>{

    if(selectedStudy && selectedSubject)
    {
        getSubSubjectsForStudy();
    }


  },[selectedSubject])

  const loadInitial = async() =>{


    const studiesResponse =    await Helpers.getStudies();
    if(studiesResponse)
    {
        setStudies(studiesResponse);
    }
  }

  const getSubjectsForStudy = async() =>{

    setSubjects([]);
    setSubSubjects([]);
    setSelectedSubSubjects([])

    const subjectsResponse =    await Helpers.getSubjects(selectedStudy);
    if(subjectsResponse)
    {
        setSubjects(subjectsResponse);
    }
  }

  const getSubSubjectsForStudy = async() =>{
    
    //setSubjects([]);
    setSubSubjects([]);
    setSelectedSubSubjects([])
    
 
    



    const subjectsResponse =    await Helpers.getSubjects(selectedStudy, selectedSubject);
    if(subjectsResponse)
    {
        setSubSubjects(subjectsResponse);
        console.log("subsubjects are the folllowing", subjectsResponse);

       var selectedSubjects =  subjectsResponse.map(subject => subject._id);
       console.log("subsubjects are the folllowing after tweak", selectedSubjects);

       setSelectedSubSubjects(selectedSubjects);
    }
  }
  const getSubSubSubjectsForStudy = async(subSubject) =>{

    const newdata = subSubjects.filter(obj => obj._id === subSubject)
    if(newdata[0].children)
    {

    }
    else
    {
        const subjectsResponse =    await Helpers.getSubjects(selectedStudy, subSubject);
        if(subjectsResponse)
        {
    
            console.log(subjectsResponse);
    
            newdata[0].children = subjectsResponse;
            console.log(newdata[0]);
    
            setSubSubjects((prevState)=>
                prevState.map(( data ) =>
                  data._id === newdata[0]._id ? newdata[0]  :  data 
                )
              );


              var selectedSubjects =  subjectsResponse.map(subject => subject._id);
              console.log("subsubjects are the folllowing after tweak", selectedSubjects);
       

              setSelectedSubSubjects((prevState=>([...prevState, ...selectedSubjects ])))
        }
    }

    
  }
  return (
    <Card p={8} maxWidth="600px" mx="auto" mt={10} borderWidth="1px" borderRadius="lg" boxShadow="lg" bg={boxBgnew}> 
      <VStack spacing={6}>
        <Heading as="h2" size="lg">New Test</Heading>

        {/* Dropdown for Database */}
        <FormControl>
          <FormLabel>Database</FormLabel>
          <Select placeholder="Select database" onChange={(event)=>setSelectedStudy(event.target.value)}>
            {studies.length >  0 && studies.map((study)=>(<option value={study._id}>{study.title}</option>)



            )}
          </Select>
        </FormControl>

        {/* Dropdown for Subject */}
       {subjects.length > 0 &&  <FormControl>
          <FormLabel>Subject</FormLabel>


          <Select placeholder="Select subject" onChange={(event)=>setSelectedSubject(event.target.value)}>
            {subjects.map((subject)=>(<option value={subject._id}>{subject.title}</option>)



            )}
          </Select>
        </FormControl>}

        {/* Buttons for Exam and Study */}
        <Stack direction="row" spacing={4}>
          <Button colorScheme="blue" onClick={() => handleStartClick('exam')}>Start Exam</Button>
          <Button colorScheme="green" onClick={() => handleStartClick('study')}>Start Study</Button>
        </Stack>

        {/* Sub-subjects Table and Filters (Only shown after selecting Exam or Study) */}
        {showOptions  &&  (
          <>
               {subSubjects.length > 0 &&<Heading as="h3" size="md">Select Sub Subjects</Heading>}
            {subSubjects.length > 0 && <Accordion allowMultiple>
              {subSubjects && subSubjects.map((sub) => (
                <AccordionItem key={sub._id} onClick={()=>getSubSubSubjectsForStudy(sub._id)}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Checkbox value={sub._id} onChange={(event)=>subSubjectChange(event.target.value, sub?.children)} isChecked={selectedSubSubjects.length ? selectedSubSubjects.includes(sub._id) : false} mr={2} /> {sub.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack align="flex-start"  pl={3 }>
                      {sub?.children && sub?.children.map((subSub, index2) => (
                        <Checkbox key={index2} value={subSub._id} onChange={(event)=>subSubjectChange(event.target.value)} isChecked={selectedSubSubjects.length ? selectedSubSubjects.includes(subSub._id) : false}>{subSub.title}</Checkbox>
                      ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>}

            {/* Filters */}
            <Heading as="h4" size="sm" mt={4}>Filters</Heading>
            <VStack align="flex-start">
            <Checkbox isChecked={filters.withImages} onChange={() => handleFilterChange('withImages')}>
               With Images
              </Checkbox>
              <Checkbox isChecked={filters.onlyRealExam} onChange={() => handleFilterChange('onlyRealExam')}>
                Only real exam questions
              </Checkbox>
              <Checkbox isChecked={filters.reviewFlagged} onChange={() => handleFilterChange('reviewFlagged')}>
                Review questions
              </Checkbox>

              <Checkbox isChecked={filters.markFlagged} onChange={() => handleFilterChange('markFlagged')}>
                Marked questions
              </Checkbox>
              <Checkbox isChecked={filters.greenFlagged} onChange={() => handleFilterChange('greenFlagged')}>
                Green flagged questions
              </Checkbox>
        
              <Checkbox isChecked={filters.redFlagged} onChange={() => handleFilterChange('redFlagged')}>
                Red flagged questions
              </Checkbox>
              <Checkbox isChecked={filters.yellowFlagged} onChange={() => handleFilterChange('yellowFlagged')}>
                Yellow flagged questions
              </Checkbox>
              <Checkbox isChecked={filters.withNotes} onChange={() => handleFilterChange('withNotes')}>
                With my notes
              </Checkbox>
              <Checkbox isChecked={filters.incorrectAnswers} onChange={() => handleFilterChange('incorrectAnswers')}>
                Incorrectly answered
              </Checkbox>
            </VStack>

            {/* Start Exam/Study Button */}
            <Button colorScheme="teal" mt={4} onClick={()=>handleStartTestClick()}>
              {selectedMode === 'exam' ? 'Start Your Exam' : 'Start Your Study'}
            </Button>
          </>
        )}
      </VStack>
    </Card>
  );
};

export default Test;
