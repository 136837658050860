module.exports = {
back_to_home:"Back to SuvvAator",
sign_in_with_google:"Sign in with Google",
after_sign_in_header_text:"Enter your email and password to sign in!",
email:"Email",
password:"Password",
phone:"Phone",
sign_in:"Sign In",
profile:"Profile",
forgot_password: "Forgot Password ?",
create_an_account:"Create an Account",
email_placeholder:"youremail@domain.com",
password_placeholder:"Min. 8 characters",
not_registred_yet:"Not Registered Yet ?",
password_not_completed:"Password is not completed",
complete_your_password:"Please complete your password",
singing_loading:"Signing in...",
newusers_this_month: "Signups this month",
total_questions: "Total Questions",
databases: "Databases",
tests_run_today: "Today Tests",
tests_this_month: "This Month Tests",
new_users: "New Users",
full_name: "Full Name",
date: "Date",
date_added: "Date Added",
date_modified: "Date Modified",
country: "Country",
profile_settings: "Profile Settings",
logout: "Logout",
newsletter_settings: "Newsletter Settings",
main_dashboard: "Main Dashboard",
users: "Users",
questions: "Questions",
question: "Question",
databases: "Databases",
subjects: "Subjects",
plans: "Plans",
edit: "Edit",
save: "Save",
delete: "Delete",
edit_user: "Edit User",
add_user: "Add User",
total_pages:"Total Pages",
total_results:"Total Results",
total_questions:"Total Questions",
total_users:"Total Users",

delete_user: "Delete User",
add_question: "Add Question",
delete_question: "Delete Question",
edit_question: "Edit Question",
update_question: "Update Question",
answer: "Answer",
add_answer: "Add New Answer",
delete_answer: "Delete Answer",
explanation: "Explanation",
explanation_images: "Explanation Images",
study: "Database",
add_study: "Add New Study",
delete_study: "Delete Study",
add: "Add",
only_real_exams: "Only Real Exam Questions",
all: "All",
real_exams: "Real Exams",
approved: "Approved",
pending: "Pending",
approve: "Approve",
marked_by: "Marked by",
logs: "Logs",
action:"Action",
subject:"Subject",
add_subject: "Add Subject",
delete_subject: "Delete Subject",
edit_subject: "Edit Subject",
add_database: "Add Database",
delete_database: "Delete Database",
edit_database: "Edit Database",
add_plan: "Add Plan",
delete_plan: "Delete Plan",
edit_plan: "Edit Plan",
media: "Media",
bulk_importer: "Bulk Importer",
actions: "Actions",
firstName:"First Name",
lastName:"Last Name",
address:"Address",
city:"City",
role:"Role",
username:"Role",
search_dots:"Search...",
edit_user:"Edit User",
street_address:"Street Address",
postal_code:"Postal Code",
country:"Country",
state:"State",
select_country:"Select Country",
saving:"Saving",
delete_user:"Delete User",
update_user:"Update User",
deleting:"Deleting",
user:"User",
status:"Status",
active:"Active",
draft:"Draft",
pending:"Pending",
default_answers_status:"Default Answers Status",



}