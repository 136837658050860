import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdNewLabel,
  MdHome,
  MdLock,
  MdMessage,
  MdOutlineShoppingCart,
  MdOutlinePerson,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import MainUserDashboard from "views/user/default";
import NewTest from "views/user/newtest";
import Test from "views/user/test";
import Messages from "views/user/messages";
import Profile from "views/user/profile";
import MessagesAdmin from "views/admin/messages";

import NFTMarketplace from "views/admin/marketplace";
import Users from "views/admin/users";
import Questions from "views/admin/questions";
import Logs from "views/admin/logs";
import Plans from "views/admin/plans";

import RTL from "views/admin/rtl";
import UserEdit from "views/admin/user-edit";
import QuestionEdit from "views/admin/question-edit";
import PlanEdit from "views/admin/plan-edit";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";

import default_language from "config/default_language";
import Helpers from "helpers";
const routes = [
  {
    name: default_language.main_dashboard,
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },

  {
    name: default_language.main_dashboard,
    layout: "/user",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainUserDashboard,
  },

  {
    name: "Test",
    layout: "/user",
    path: "/test/:testid",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    exclude_from_menu:true,
    component: ()=> <Test default_language={default_language}  />
  },
  {
    name: "Profile",
    layout: "/user",
    path: "/profile",
    icon: <Icon as={MdOutlinePerson} width='20px' height='20px' color='inherit' />,
    component: ()=> <Profile default_language={default_language}  />
  },
  {
    name: default_language.logs,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/logs",
    exclude_for_moderators:true,

    component: ()=> <Logs default_language={default_language} />
  },

  {
    name: default_language.plans,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/plans",
    exclude_for_moderators:true,

    component: ()=> <Plans default_language={default_language} />
  },
  {
    name: "New test",
    layout: "/user",
    path: "/newtest",

    icon: <Icon as={MdNewLabel} width='20px' height='20px' color='inherit' />,
    component: NewTest,
  },

  {
    name: "Messages",
    layout: "/user",
    path: "/messages",

    icon: <Icon as={MdMessage} width='20px' height='20px' color='inherit' />,
    component: Messages,
  },

  {
    name: "Messages",
    layout: "/admin",
    path: "/messages",

    icon: <Icon as={MdMessage} width='20px' height='20px' color='inherit' />,
    component: MessagesAdmin,
  },

  {
    name: default_language.users,
    layout: "/admin",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    path: "/users",
    component: ()=> <Users default_language={default_language} />
  },
  {
    name: default_language.users,
    layout: "/admin",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    path: "/user/:userId",
    exclude_from_menu:true,
    component: ()=> <UserEdit default_language={default_language}  />
  },
  {
    name: default_language.questions,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/questions",
    component: ()=> <Questions default_language={default_language} />
  },
  {
    name: default_language.users,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/question/:questionId",
    exclude_from_menu:true,
    component: ()=> <QuestionEdit default_language={default_language}  />
  },
  {
    name: default_language.plans,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/plan/:planId",
    exclude_from_menu:true,
    component: ()=> <PlanEdit default_language={default_language}  />
  },
  {
    name: default_language.questions,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/questionstest",
    component: NFTMarketplace,
    exclude_from_menu:true,

    hasChildren:true,
    children: [
      {
        name: default_language.databases,
        layout: "/admin",
        icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
        path: "/questions/databases",
        component: Users,
        secondary: true,
      },
      {
        name: default_language.subjects,
        layout: "/admin",
        icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
        path: "/questions/subjects",
        component: Users,
      }


    ]
  },
  {
    name: default_language.plans,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/plans",
    exclude_from_menu:true,

    component: ()=> <Plans default_language={default_language} />
  },
  {
    name: default_language.bulk_importer,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/bulk-importer",
    exclude_from_menu:true,

    component: Users,
  },
  {
    name: default_language.media,
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/media",
    exclude_from_menu:true,

    component: Users,
  },
  {
    name: default_language.profile,
    layout: "/admin",
    path: "/profile",
    exclude_from_menu:true,

    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },

  {
    name: default_language.sign_in,
    layout: "/auth",
    exclude_from_menu:true,

    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ()=> <SignInCentered isAuthenticated={Helpers.isTokenExpired()? false: true}/>,
  },

  {
    name: "Sign Up",
    layout: "/auth",
    exclude_from_menu:true,

    path: "/sign-up",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ()=> <SignUpCentered isAuthenticated={Helpers.isTokenExpired()? false: true}/>,
  },
  {
    name: "RTL Admin",
    exclude_from_menu:true,

    layout: "/rtl",
    path: "/rtl-default",
    exclude_from_menu:true,

    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: RTL,
  },
];

export default routes;
