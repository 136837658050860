import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand(props) {

  console.log("brand");
  console.log(props);

  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  return (
    <Flex align='center' direction='column'>
      <img src="/logo.png" width="240px" style={{marginBottom:20}}/>

      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
