/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {useState, useEffect} from "react";
import { NavLink, Redirect } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  ChakraProvider,
  createStandaloneToast
} from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import default_lang from "../../../config/default_language";
import config from "../../../config/default";
import Helpers from '../../../helpers';

function SignIn(props) {


  const toast = createStandaloneToast()





  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [loginButtonisDisabled, setLoginButtonIsDisabled] = useState(true);

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentError, setCurrentError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [role, setRole] = useState("user");

  const [isAuthenticated, setIsAuthenticated] = useState(props.isAuthenticated);
  


  useEffect(()=>{
    if(currentEmail && currentPassword)
    {
      setLoginButtonIsDisabled(false);
    }
    else
    {
      if(loginButtonisDisabled==false)
      {
        setLoginButtonIsDisabled(true);
      }

    }

  }, [currentEmail, currentPassword])



  const onSignIn = async() =>
  {
    setCurrentError(null);  

    if(!currentPassword)
    {
       setCurrentError({title:default_lang.password_not_completed, description:default_lang.complete_your_password})

    }
    if(!currentEmail)
    {
       setCurrentError({title:default_lang.password_not_completed, description:default_lang.complete_your_password})

    }
    if(currentEmail && currentPassword)
    {
       setLoginLoading(true);

       const response =  await Helpers.loginFunc(currentEmail, currentPassword).then((response)=> {
        if(response.token)
        {
          setLoginLoading(false);
          Helpers.setAuthToken(response.token);
          setIsAuthenticated(true);
          Helpers.getProfileData().then((response=>
            {
              if(response?.role == "admin")
              {
                setRole(response?.role);

              }
              if(response?.role == "admin")
                {
                  setRole("admin");
  
                }
                if(response?.role == "student")
                  {
                    setRole("user");
    
                  }
            }));
  
        }
        else{
          setLoginLoading(false);
          console.log(response);
          toast({
            title: 'Error.',
            description: response.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
            })

        }
       
       }).catch((err)=>{
        setLoginLoading(false);
       });
     
    
    
    }


  }





  useEffect(()=>{

   
if (!Helpers.isTokenExpired()) {

  setIsAuthenticated(true);
       


} else {
  
  // Handle expired token (e.g., prompt for re-login)
}

  }, [])


  

  const handleClick = () => setShow(!show);
  return (

    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            {default_lang.sign_in}
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            {default_lang.after_sign_in_header_text}
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Button
          style = {{display:'none'}}
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive} 
            >
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            {default_lang.sign_in_with_google}
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>
           {currentError && <Alert status='error'>
            <AlertIcon />
            <AlertTitle>{currentError.title}</AlertTitle>
            <AlertDescription>{currentError.description}</AlertDescription>
            </Alert>}
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              {default_lang.email}<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder={default_lang.email_placeholder}
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=>setCurrentEmail(event.target.value)}
            />
           
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              {default_lang.password}<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder={default_lang.password_placeholder}
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                 onChange={(event)=>setCurrentPassword(event.target.value)}

              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  {default_lang.forgot_password}
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              isLoading={loginLoading}
              isDisabled={loginButtonisDisabled}
              loadingText={default_lang.singing_loading}
              onClick={(event)=> onSignIn(event)}>
              {default_lang.sign_in}
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              {default_lang.not_registred_yet}
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  {default_lang.create_an_account}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isAuthenticated && <Redirect
                  from='/auth'
                  to={'/'+role}
                />}

    </DefaultAuth>
  );
}

export default SignIn;
